.d-flex{display: flex !important;}
.p-relative{position: relative !important;}
.c-pointer{cursor: pointer !important;}
.d-block{display: block !important;}
.l-height-normal{line-height: normal !important;}

/*----Font Sizes----*/
 .fs-10{font-size: 10px !important;}
.fs-12{font-size: 12px !important;}
.fs-14{font-size: 14px !important;}
.fs-16{font-size: 16px !important;}
.fs-18{font-size: 18px !important;}
.fs-32{font-size: 32px !important;}
.fs-28{font-size: 28px !important;}
.fs-22{font-size: 22px !important;}
/* .fs-20{font-size: 20px !important;}
.fs-26{font-size: 26px !important;}
.fs-30{font-size: 30px !important;}
.fs-40{font-size: 40px !important;} */ 

/*--Font Weights--*/
.fw-100{font-weight: 100 !important;}
.fw-200{font-weight: 200 !important;}
.fw-300{font-weight: 300 !important;}
.fw-400{font-weight: 400 !important;}
.fw-500{font-weight: 500 !important;}
.fw-600{font-weight: 600 !important;}
.fw-700{font-weight: 700 !important;}
.fw-800{font-weight: 800 !important;}
.fw-900{font-weight: 900 !important;}

/*--Alignments--*/
.text-center{text-align: center !important;}
.text-right{text-align: right !important;}
.text-left{text-align: left !important;}
.text-upper{text-transform: uppercase !important;}
.text-captz{text-transform: capitalize !important;}
.text-lower{text-transform: lowercase !important;}
.align-center{align-items: center;}
.align-end{align-items: flex-end;}
.justify-content{justify-content: space-between;}
.text-underline{text-decoration: underline !important;}
.valign-initial{vertical-align: initial;}

/*--Text Colors--*/
.text-purewhite{color: var(--textPureWhite) !important;}
.text-white{color: var(--textWhite) !important;}
.text-white-30{color: var(--textWhite30) !important;}
.text-white-50{color: var(--textWhite50) !important;}
.text-textDark{color: var(--textBlack) !important;}
.text-green{color: var(--textGreen) !important;}
.text-orange{color: var(--textOrange) !important;}
.text-darkgreen{color: var(--textDarkGreen) !important;}
.text-red{color: var(--textRed) !important;}
.text-secondary{color: var(--textSecondary) !important;}
.text-yellow{color: var(--textYellow) !important;}
.textpure-yellow{color: var(--bgDarkYellow) !important;}
.text-defaultylw{color: var(--textDefautlYlw) !important;}
.text-aqua{color: var(--textAqua) !important;}
.text-purple{color: var(--textpurple) !important;}
.bggrey{background-color: var(--bgGrey) !important;}
.bgred{ background-color: var(--bgDarkRed) !important;border-color: var(--bgDarkRed) !important;}
.bggreen{ background-color: var(--bgDarkGreen) !important;border-color: var(--bgDarkGreen) !important;}


/*----Padding Utilities----*/
.p-0{padding: 0 !important;}
.p-16{padding: 16px !important;}
.p-24{padding: 24px !important;}
.p-28{padding: 28px;}
.pt-16{padding-top: 16px !important;}
.pt-24{padding-top: 24px !important;}
.pt-0{padding-top: 0 !important;}
.pb-0{padding-bottom: 0 !important;}
.pb-8{padding-bottom: 8px !important;}
.pb-16{padding-bottom: 16px !important;}
.pb-30{padding-bottom: 30px !important;}
.pl-0{padding-left: 0 !important;}
.pr-0{padding-right: 0 !important;}
.pr-30{padding-right: 30px !important;}
.pl-30{padding-left: 30px !important;}
.px-0{padding-left: 0 !important; padding-right: 0 !important;}
.px-4{padding-left: 4px !important; padding-right: 4px !important;}
.px-8{padding-left: 8px !important; padding-right: 8px !important;}
.px-16{padding-left: 16px !important; padding-right: 16px !important;}
.px-24{padding-left: 24px !important; padding-right: 24px !important;}
.px-36{padding-left: 36px !important; padding-right: 36px !important;}
.py-4{padding-top: 4px !important; padding-bottom: 4px !important;}
.py-8{padding-top: 8px !important; padding-bottom: 8px !important;}
.py-10{padding-top: 10px !important; padding-bottom: 10px !important;}
.py-16{padding-top: 16px !important; padding-bottom: 16px !important;}
.py-20{padding-top: 20px !important; padding-bottom: 20px !important;}
.py-24{padding-top: 24px !important; padding-bottom: 24px !important;}
.py-36{padding-top: 36px !important; padding-bottom: 36px !important;}

/*----Margin Utilities----*/
.m-0{margin: 0 !important;}
.mb-0{margin-bottom: 0 !important;}
.mb-4{margin-bottom: 4px !important;}
.mb-8{margin-bottom: 4px !important;}
.mb-16{margin-bottom: 16px !important;}
.mb-24{margin-bottom: 24px !important;}
.mb-28{margin-bottom: 28px !important;}
.mb-36{margin-bottom: 36px !important;}
.mt-4{margin-top: 4px;}
.mt-8{margin-top: 8px !important;}
.mt-12{margin-top: 12px !important;}
.mt-16{margin-top: 16px !important;}
.mt-24{margin-top: 24px !important;}
.mt-36{margin-top: 36px !important;}
.ml-4{margin-left: 4px !important;}
.ml-8{margin-left: 8px !important;}
.ml-12{margin-left: 12px !important;}
.ml-16{margin-left: 16px !important;}
.ml-24{margin-left: 24px !important;}
.ml-36{margin-left: 36px !important;}
.ml-auto{margin-left: auto !important;}
.mx-auto{margin-left: auto !important;margin-right: auto !important;}
.mr-4{margin-right: 4px;}
.mr-0{margin-right: 0px !important;}
.mr-8{margin-right: 8px !important;}
.mr-12{margin-right: 12px !important;}
.mr-16{margin-right: 16px !important;}
.mr-20{margin-right: 20px !important;}
.mr-36{margin-right: 36px !important;}
.mx-0{margin-left: 0 !important;margin-right: 0 !important;}
.mx-4{margin-left: 4px !important;margin-right: 4px !important;}
.mx-12{margin-left: 12px !important;margin-right: 12px !important;}
.mx-24{margin-left: 24px !important;margin-right: 24px !important;}
.mx-30{margin-left: 30px !important;margin-right: 30px !important;}
.my-16{margin-top: 16px !important;margin-bottom: 16px !important;}
.my-22{margin-top: 22px !important;margin-bottom: 22px !important;}
.my-30{margin-top: 30px !important;margin-bottom: 30px !important;}
/* .my-36{margin-top: 36px !important;margin-bottom: 36px !important;} */

@media (min-width:1400px) {
    .my-36{margin-top: 36px !important;margin-bottom: 36px !important;}
    .fs-18{font-size: 18px !important;}
    .fs-20{font-size: 20px !important;}
    .fs-26{font-size: 26px !important;}
    .fs-28{font-size: 28px !important;}
    .fs-30{font-size: 30px !important;}
    .fs-36{font-size: 36px !important;}
    .fs-40{font-size: 40px !important;}
    .mb-36{margin-bottom: 36px !important;}
    }
@media (max-width: 991px){
    .visible-xs{visibility: visible;}
    .hidden-xs{visibility: hidden;}
}
@media (min-width: 576px){
    .fs-36{font-size: 32px !important;}
    .fs-24{font-size: 24px !important;}
}
@media (min-width: 320px){
    .fs-24{font-size: 18px !important;}
    .fs-18{font-size: 16px !important;}
    .fs-20{font-size: 18px !important;}
    .fs-26{font-size: 24px !important;}
    .fs-28{font-size: 26px !important;}
    .fns-28{font-size: 28px !important;}
    .fs-30{font-size: 30px !important;}
    .fs-36{font-size: 36px !important;}
    .fs-40{font-size: 40px !important;}
    .fs-42{font-size: 42px !important;}
    .my-36{margin-top: 24px !important;margin-bottom: 24px !important;}
    .mb-36{margin-bottom: 24px !important;}
}



