@import url("../fonts/stylesheet.css");

* {
	scrollbar-width: thin;
	scrollbar-color: var(--bgGrey) var(--bgDarkGrey);
}

*::-webkit-scrollbar {
	width: 12px;
	height: 10px;
}

*::-webkit-scrollbar-thumb {
	background-color: var(--bgDark1);
	border-radius: 20px;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p div {
	font-family: "SF Pro Text", sans-serif !important;
}

.ant-layout,
body {
	background-color: var(--bgDarkGrey);
}

.ant-btn {
	font-weight: var(--fw500);
}

.ant-drawer-close {
	display: none;
}

.d-inlineblock {
	display: inline-block !important;
}

.loader-antd {
	padding: 10px 20px;
	text-align: center;
	color: var(--bgWhite);
}

.ant-empty-description {
	color: var(--textWhite30);
}

.ant-alert {
	border-radius: 10px;
	margin-bottom: 16px;
}

.ant-alert-message {
	font-size: 18px !important;
	font-weight: 700;
	color: var(--textDark) !important;
	line-height: normal;
}

.ant-alert-description {
	font-size: 16px !important;
	color: var(--textSecondary) !important;
}

.ant-input-search .ant-input-group input {
	background-color: var(--bgDarkGrey) !important;
	height: 38px;
	border-radius: 30px;
	color: var(--textWhite30);
	padding-left: 18px;
}

.ant-input-search .ant-input-group .ant-input-group-addon {
	background-color: var(--bgDarkGrey) !important;
	border-radius: 0 30px 30px 0;
}

.grey-search.ant-input-search .ant-input-group input,
.grey-search.ant-input-search .ant-input-group .ant-input-group-addon {
	background-color: var(--bgGrey) !important;
}

.ant-input-search .ant-input-group-addon .ant-btn {
	background-color: transparent;
	border: none;
	width: 42px;
	box-shadow: none;
}

.ant-dropdown-arrow {
	width: 10px;
	height: 10px;
	background-color: var(--bgGloom);
	top: 5px !important;
}

.depwith-drpdown .ant-dropdown-menu {
	min-width: 173px !important;
	max-width: 173px !important;
}

.dark-search+.ant-table-wrapper .ant-table-container {
	background-color: var(--bgGrey) !important;
}

/*--Dashboard--*/
.db-container {
	padding: 0;
}

.db-kpi {
	background-color: var(--bgGrey);
	border-radius: 20px;
	padding: 16px 24px;
	margin-bottom: 16px;
	min-height: 112px;
}

.db-kpi-label {
	font-size: 12px;
	color: var(--textWhite50);
	display: block;
}

.db-kpi-val {
	font-size: 24px;
	color: var(--textWhite30);
	font-weight: 500;
	line-height: 34px;
	margin: 0 16px 3px 0;
	display: block;
}

.db-kpi .badge {
	background-color: var(--bgDarkGrey);
	padding: 3px 10px;
	font-size: 12px;
	font-weight: 500;
	color: #d13f00;
	border-radius: 16px;
	vertical-align: text-bottom;
	display: inline-block;
}

.db-kpi .icon-bg {
	width: 48px;
	height: 48px;
	border-radius: 8px;
	background-color: var(--bgDarkGrey);
	margin-right: 20px;
	text-align: center;
	line-height: 48px;
}

.db-kpi.vthicon {
	display: flex;
	align-items: center;
	min-height: 112px;
}

.graph {
	border-radius: 20px;
	margin-bottom: 16px;
	background-color: var(--bgGloom);
	border-color: var(--borderGrey);
}

.graph .ant-card-head {
	border-color: var(--borderGrey);
}

.graph .ant-card-body {
	min-height: 430px;
	display: flex;
	justify-content: center;
	align-items: center;
}

#graph-container,
#container {
	width: 100%;
	height: 100%;
}

.markets-panel .ant-list-split .ant-list-item,
.portfolio-list .ant-list-split .ant-list-item {
	border-color: var(--borderGrey) !important;
	padding: 14px 0;
	transition: 0.3s ease;
}

.portfolio-list h1 {
	padding: 36px 0 16px;
}

.portfolio-list .ant-list-split .ant-list-item {
	border-top: 1px solid var(--borderDark) !important;
}

.portfolio-list .ant-list-split .ant-list-item:last-child {
	border-bottom: 1px solid var(--borderDark) !important;
}

.dbchart-link {
	color: var(--textDark) !important;
	display: inline-block;
	background-color: var(--bgYellow);
	padding: 0 12px;
	margin-left: 8px;
	border-radius: 12px;
	line-height: 24px;
}

.btn-primary {
	background-color: var(--btnPrimary) !important;
}

.login-user,
.side-drawer .side-drawer-header,
.side-drawer-full .side-drawer-header,
.wire-trans,
.crypto-card-bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header-logo {
	list-style: none;
	display: inline-flex;
	padding-right: 0 !important;
	align-items: center;
	margin-bottom: 0;
}

.tlv-logo {
	height: 45px;
}

.header-logo li:first-child::after {
	content: "";
	position: absolute;
	width: 1px;
	height: 27px;
	right: 0;
	top: 10px;
	background-color: var(--bgWhiteSpin);
}

.tlv-header .list-item.ant-menu-item .ant-menu-title-content {
	padding: 6px 12px;
}

.tlv-header .list-item.ant-menu-item-selected .ant-menu-title-content,
.tlv-header .list-item.ant-menu-item-active .ant-menu-title-content {
	background-image: linear-gradient(99.09deg,
			var(--btnGrad1) 0%,
			var(--btnGrad2) 100%);
	filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
	margin: 0px;
	border-radius: 5px;
	border: 0;
	line-height: 16px;
	min-width: 90px;
	color: var(--textPureWhite);
}

.custom-btn {
	border-radius: 5px;
	border: 0;
	line-height: 18px;
	min-width: 90px;
	height: 36px;
	font-weight: 300;
	font-size: 16px;
	filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
	transition: 0.5s ease;
}

.custom-btn.outline {
	border-color: var(--bgDarkGrey) !important;
}

.custom-btn.prime {
	background-image: linear-gradient(99.09deg, var(--btnGrad1), var(--btnGrad2));
}

.custom-btn.sec {
	background: transparent;
	background-image: none;
	border: 1px solid var(--btnBorder);
	color: var(--textWhite);
}

.custom-btn.sec:hover {
	background-color: var(--bgWhite);
	color: var(--textDark);
}

.carousel-card {
	background-color: var(--bgDarkYellow);
	border-radius: 30px;
	overflow: hidden;
	min-height: 192px;
}

.ant-carousel .slick-dots-bottom {
	bottom: 10px !important;
}

.ant-carousel .slick-dots li button {
	background: var(--bgGrey) !important;
	opacity: 0.5;
	height: 4px;
	border-radius: 2px;
}

.ant-carousel .slick-dots li.slick-active button {
	background: var(--bgGrey) !important;
	opacity: 1;
}

.ant-carousel .slick-dots li {
	width: 8px;
}

.ant-carousel .slick-dots li.slick-active {
	width: 30px;
}

.tlv-header {
	background-color: var(--bgGrey) !important;
	position: fixed;
	z-index: 16;
	width: 100%;
}

.tlv-header.inital-header {
	background-color: transparent !important;
}

.tlv-header ul {
	background-color: transparent;
	border-bottom: 0;
}

.tlv-header ul li {
	font-weight: 300;
}

.tlv-header .main-menu li {
	border-radius: 5px;
}

.tlv-header .main-menu li.ant-menu-item-selected,
.tlv-header .main-menu li:hover {
	background-color: var(--primary) !important;
	color: var(--textWhite) !important;
}

.tlv-header li::after,
.tlv-header li:hover:after {
	border: none !important;
}

.ant-modal.megamenu {
	top: 0 !important;
	bottom: 0 !important;
	width: 100% !important;
	max-width: 100%;
}

.megamenu-backdrop,
.megamenu .ant-modal-header {
	background-color: var(--bgGloom);
}

.megamenu .ant-modal-header,
.drpdwn-list li:last-child a {
	border: none;
}

.megamenu-title {
	display: flex;
	justify-content: space-between;
	padding-right: 60px;
	align-items: center;
}

.megamenu .ant-modal-content {
	box-shadow: none;
	background-color: var(--bgGloom) !important;
	min-height: calc(100vh - 70px);
	max-height: calc(100vh - 70px);
}

.megamenu-link a,
.megamenu-sublink a,
.megamenu-link .menu_Link {
	font-size: 18px;
	color: var(--textWhite30);
	display: block;
	font-weight: 300;
	cursor: pointer;
	margin-bottom: 0;
}

.megamenu-sublink a {
	font-size: 14px;
	margin-bottom: 24px;
}

.megamenu-link a:hover,
.megamenu-sublink a:hover,
.megamenu-link .menu_Link:hover {
	color: var(--textAqua) !important;
}

.custom-toggle.ant-switch {
	background-color: var(--bgBlack) !important;
}

.custom-toggle.ant-switch-checked {
	background-color: var(--textError) !important;
}

.secureDropdown .ant-dropdown-menu {
	background-color: var(--bgGloom);
	border-radius: 10px;
	min-width: 300px;
	max-width: 300px;
	padding: 24px 0;
}

.secureDropdown .ant-dropdown-arrow {
	border-color: var(--bgGloom) !important;
}

.drpdwn-list {
	list-style: none;
}

.drpdwn-list li {
	font-size: 14px;
	padding: 0 30px;
	transition: 0.3s ease;
	cursor: pointer;
}

.drpdwn-list li:hover {
	background-color: var(--bgDarkGrey);
}

.drpdwn-list li a {
	color: var(--textWhite);
	display: flex;
	align-items: center;
	padding: 10px 0 10px 10px;
}

.drpdwn-list li:last-child a,
.dropdown-flex-top {
	border-top: 1px solid var(--bgDarkGrey);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.drpdwn-list li:first-child a:first-child {
	border-top: none;
}

.dropdown-flex {
	border-bottom: 1px solid var(--bgDarkGrey);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.drpdwn-list li.no-hover:hover {
	background-color: transparent !important;
}

.drpdwn-list li span.icon {
	transition: margin 0.3s ease-in;
	margin-left: auto;
}

.drpdwn-list li:hover span.icon {
	margin-right: -3px;
}

.file-label {
	border: 1px solid var(--borderLight);
	border-radius: 6px;
	padding: 2px 8px;
	color: var(--textYellow);
	background-color: var(--bgDarkGrey);
}

.icon,
.k-i-more-vertical.k-icon,
.custom-checkbox input[type="checkbox"]+span {
	background: var(--lightThemeIcons) no-repeat;
	display: inline-block;
	vertical-align: middle;
}

.icon.xl {
	width: 32px;
	height: 32px;
}

.icon.md {
	width: 20px;
	height: 20px;
}

.icon.xxl {
	width: 50px;
	height: 50px;
}

.icon.xxxl {
	width: 70px;
	height: 70px;
}

.icon.xl.closewhite {
	background-position: -2px -243px;
}

.icon.xl.overview {
	background-position: -14px -476px;
}

.icon.xl.activeView {
	background-position: -202px -33px;
}

.card-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .tab-block .overview {
	background-position: -11px -509px;
	background-color: #e5c204;
	width: 40px;
	height: 40px;
	border-radius: 50px;
	margin-bottom: 6px !important;
}

.icon.xl.physical-card {
	background-position: -54px -476px;
}

.card-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .tab-block .physical-card {
	background-position: -50px -509px;
	background-color: #e5c204;
	width: 40px;
	height: 40px;
	border-radius: 50px;
	margin-bottom: 6px !important;
}

.icon.xl.virtual-card {
	background-position: -100px -476px;
}

.card-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .tab-block .virtual-card {
	background-position: -95px -507px;
	background-color: #e5c204;
	width: 40px;
	height: 40px;
	border-radius: 50px;
	margin-bottom: 6px !important;
}

.icon.xl.settings {
	background-position: -138px -477px;
}

.card-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .tab-block .settings {
	background-position: -134px -511px;
	background-color: #e5c204;
	width: 40px;
	height: 40px;
	border-radius: 50px;
	margin-bottom: 6px !important;
}

.icon.md.close {
	background-position: -42px -70px;
}

.icon.lg {
	width: 26px;
	height: 26px;
}

.icon.lg.drawer-close {
	background-position: -1px -112px;
}

.icon.lg.check-ylw {
	background-position: -36px -111px;
}

.icon.lg.chat {
	background-position: -429px -1px;
}

.icon.md.bell {
	background-position: -1px 0;
}

.icon.md.gear {
	background-position: -30px -1px;
}

.icon.md.hamburger {
	background-position: -59px -1px;
}

.icon.md.lose {
	background-position: -271px -2px;
}

.icon.md.gain {
	background-position: -302px -2px;
}

.icon.md.download {
	background-position: -627px -38px;
}

.icon.md.downarrow {
	background-position: -59px -2px;
}

.icon.md.close-white {
	background-position: -168px 0;
}

.icon.md.date-white {
	background-position: -480px -37px;
}

.icon.md.search-white {
	background-position: -197px 0;
}

.icon.md.load {
	background-position: -242px 0;
	float: right;
	margin-top: 2px;
}

.icon.md.info {
	background-position: -74px -331px;
	transform: scale(0.8);
	width: 24px;
	height: 24px;
}

.icon.md.lftarw-white {
	background-position: -377px 0;
}

.icon.md.downarrow-icon {
	background-position: -350px 0;
}

.icon.md.history-white {
	background-position: -272px -33px;
}

.icon.md.card {
	background-position: -480px -1px;
}

.icon.md.phone {
	background-position: 0px -35px;
}

.icon.md.qrcode {
	background-position: -140px -34px;
}

.icon.md.swaparrow {
	background-position: -236px -35px;
}

.icon.md.sentarrow {
	background-position: -307px -35px;
}

.icon.md.recivearrow {
	background-position: -338px -35px;
}

.icon.md.check-arrow {
	background-position: -366px -36px;
}

.icon.md.camera {
	background-position: -400px -34px;
}

.icon.md.eye {
	background-position: -173px -34px;
}

.icon.md.address-book {
	background-position: -239px -70px;
}

.icon.md.approve {
	background-position: -184px -70px;
}

.icon.md.profit-arw {
	background-position: -308px -34px;
}

.icon.md.lose-arw {
	background-position: -338px -34px;
}

.icon.lg.profile-icon {
	background-position: -4px -329px;
}
.icon.lg.referral-icon{
	background-position: -242px -330px;
}

.icon.lg.security-icon {
	background-position: -107px -330px;
}

.icon.lg.settings-icon {
	background-position: -38px -330px;
}

.icon.lg.info-icon {
	background-position: -73px -330px;
}

.icon.lg.logout-icon {
	background-position: -73px -330px;
}

.icon.lg.documents-icon {
	background-position: -176px -329px;
}

.icon.lg.addressbook-icon {
	background-position: -140px -329px;
}

.icon.lg.card-view {
	background-position: -170px -31px;
}

.icon.lg.card-block {
	background-position: -366px -68px;
}

.icon.lg.card-settings {
	background-position: -25px 2px;
}

.icon.md.notifyIcon.deposit {
	background-position: -375px 0px;
	transform: rotateZ(235deg);
}

.icon.md.notifyIcon.deposits,
.icon.md.notifyIcon.withdraw {
	background-position: -376px -1px;
	transform: rotateZ(45deg);
}

.icon.md.notifyIcon.documents,
.icon.md.notifyIcon.cutomers {
	background-position: -156px -71px;
}

.user-labels.active .icon.lg.profile-icon,
.user-labels:hover .icon.lg.profile-icon,
.ant-tabs-tab.ant-tabs-tab-active .icon.lg.profile-icon,
.ant-tabs-tab:hover .icon.lg.profile-icon {
	background-position: -3px -363px;
}

.user-labels.active .icon.lg.referral-icon,
.user-labels:hover .icon.lg.referral-icon,
.ant-tabs-tab.ant-tabs-tab-active .icon.lg.referral-icon,
.ant-tabs-tab:hover .icon.lg.referral-icon {
	background-position: -242px -362px;
}

.user-labels.active .icon.lg.security-icon,
.user-labels:hover .icon.lg.security-icon,
.ant-tabs-tab.ant-tabs-tab-active .icon.lg.security-icon,
.ant-tabs-tab:hover .icon.lg.security-icon {
	background-position: -107px -363px;
}

.user-labels.active .icon.lg.settings-icon,
.user-labels:hover .icon.lg.settings-icon,
.ant-tabs-tab.ant-tabs-tab-active .icon.lg.settings-icon,
.ant-tabs-tab:hover .icon.lg.settings-icon {
	background-position: -38px -363px;
}

.user-labels.active .icon.lg.documents-icon,
.user-labels:hover .icon.lg.documents-icon,
.ant-tabs-tab.ant-tabs-tab-active .icon.lg.documents-icon,
.ant-tabs-tab:hover .icon.lg.documents-icon {
	background-position: -176px -363px;
}

.user-labels.active .icon.lg.addressbook-icon,
.user-labels:hover .icon.lg.addressbook-icon,
.ant-tabs-tab.ant-tabs-tab-active .icon.lg.addressbook-icon,
.ant-tabs-tab:hover .icon.lg.addressbook-icon {
	background-position: -140px -363px;
}

.icon.sm {
	width: 12px;
	height: 12px;
}

.icon.sm.downarrow {
	background-position: -107px -4px;
}

.icon.sm.uparrow {
	background-position: -87px -4px;
}

.icon.sm.rightarrow {
	background-position: -78px -40px;
}

.icon.sm.leftarrow {
	background-position: -92px -40px;
}

.icon.sm.downarrow-white {
	background-position: -148px -5px;
	transform: scale(1.1);
}

.icon.sm.uparrow-white {
	background-position: -128px -6px;
}

.icon.sm.r-arrow-o-white {
	background-position: -222px -5px;
}

.icon.sm.uparw-o-white {
	background-position: -332px -6px;
}

.icon.sm.dwnarw-o-white {
	background-position: -355px -5px;
}

.icon.sm.rightthemearrow {
	background-position: -149px -4px;
}

.icon.sm.add {
	background-position: -32px -40px;
}

.icon.sm.right-angle {
	background-position: -510px -6px;
}

.icon.md.diag-arrow {
	background-position: -111px -36px;
}

.icon.md.leftarrow {
	background-position: -376px -1px;
}

.icon.md.rarrow-white {
	background-position: -217px -1px;
}

.icon.md.signal-white {
	background-position: -68px -112px;
	width: 25px !important;
	height: 25px !important;
}

.icon.md.h-more {
	background-position: -53px -37px;
}

.icon.md.downangle {
	background-position: -352px 0;
}

.icon.md.upangle {
	background-position: -352px 0;
}

.icon.md.greyCheck {
	background-position: -97px -71px;
}

.icon.md.greenCheck {
	background-position: -71px -71px;
}

.icon.md.attach {
	background-position: -427px -33px;
}

.icon.md.send-icon {
	background-position: -452px -34px;
}

.icon.md.reply {
	background-position: -602px -4px;
}

.icon.md.file {
	background-position: -156px -70px;
}

.icon.md.buy {
	background-position: -568px -35px;
}

.icon.md.sell {
	background-position: -538px -36px;
}

.icon.md.withdraw {
	background-position: -510px -34px;
}

.icon.md.user {
	background-position: -212px -70px;
}

.icon.md.calendar {
	background-position: -481px -36px;
}

.icon.md.status {
	background-position: -365px -69px;
}

.icon.md.add-icon {
	background-position: -397px -71px;
}

.icon.md.edit-icon {
	background-position: -336px -70px;
}

.icon.md.email {
	background-position: -127px -70px;
}

.icon.xl.image {
	background-position: -47px -241px;
}

.icon.xl.file {
	background-position: -87px -241px;
}

.icon.xxxl.doc-upload {
	background-position: 1px -398px;
}

.icon.xxl.wallet-icon {
	background-position: -85px -410px;
}

.header-right li {
	padding: 0 !important;
	margin: 0 6px;
	font-size: 16px;
	color: var(--textWhite30) !important;
}

.header-dropdown {
	font-size: 24px;
	color: var(--textBlack) !important;
}

.mid-menu {
	display: flex;
	align-items: center;
}

.box {
	background-color: var(--bgGrey);
	border-radius: 30px;
	overflow: hidden;
	margin-bottom: 28px;
}

.main-container {
	padding: 24px 16px;
	margin: auto;
}

.trade-btn {
	border-radius: 5px;
	line-height: normal;
	height: 20px;
	padding: 0 4px;
}

/* Buy/sell */
.side-drawer .ant-drawer-body,
.side-drawer .ant-drawer-header,
.side-drawer-full .ant-drawer-header,
.side-drawer-full .ant-drawer-body,
.side-drawer-full .ant-table-cell,
.side-drawer-full .ant-table-tbody>tr.ant-table-row:hover>td,
.side-drawer-full .ant-table-thead th.ant-table-column-sort,
.side-drawer-full .ant-table-thead th.ant-table-column-has-sorters:hover {
	background-color: var(--bgGrey);
}

.side-drawer .ant-drawer-mask,
.side-drawer-full .ant-drawer-mask {
	background-color: var(--bgDarkGrey-95);
}

.side-drawer .ant-drawer-header,
.side-drawer-full .ant-drawer-header,
.crypto-list-tabs .ant-tabs-nav::before {
	border: none;
}

.side-drawer-header.custom-drawer-header {
	padding: 30px 46px 30px 0px !important;
}

.side-drawer-full .ant-drawer-body {
	padding-top: 0 !important;
}

.side-drawer-full .side-drawer-header {
	font-size: 22px;
	font-weight: 400;
}

.buysell-toggle {
	border-radius: 20px;
	background: var(--bgDarkGrey);
	padding: 10px;
	display: inline-block;
	/* margin: 0 auto 50px; */
}

.buysell-toggle .ant-radio-button-wrapper {
	width: auto;
	border-radius: 15px;
	border: none;
	background: transparent;
	color: var(--textYellow);
	text-transform: uppercase;
	font-weight: 600;
	padding: 20px 40px;
	line-height: 0;
	font-size: 14px;
	text-align: center;
}

.buysell-toggle .ant-radio-button {
	border-radius: 15px;
	font-size: 12px;
}

.buysell-toggle .ant-radio-button-checked {
	background: var(--bgYellow);
	border-left: none;
}

.buysell-toggle .ant-radio-button-wrapper-checked {
	color: var(--textDark) !important;
	z-index: 0;
}

.buysell-toggle .ant-radio-button-wrapper::before,
.crypto-card .ant-card-body::before,
.crypto-card .ant-card-body::after {
	content: none;
}

.crypto-list-tabs .crypto-list {
	max-height: calc(100vh - 391px);
	overflow-y: auto;
	padding-right: 10px;
}

.crypto-list-tabs .ant-input-search-button,
.ant-input-search-middle .ant-input-search-button {
	display: none;
}

.crypto-list-tabs .ant-input-group-addon .icon,
.ant-input-search .ant-input-group-addon .icon {
	margin-right: 16px !important;
}

.sellcrypto-container {
	max-height: calc(100vh - 285px);
}

.crypto-container {
	max-height: calc(100vh - 270px);
}

.suisfiat-container {
	max-height: calc(100vh - 165px);
}

.suisfiat-height {
	max-height: calc(100vh - 158px);
}

.addbook-height {
	max-height: calc(100vh - 84px);
}

.verify-container {
	max-height: calc(100vh - 140px);
}

.dep-withdraw {
	max-height: calc(100vh - 265px);
}

.selectcrypto-container {
	max-height: calc(100vh - 101px);
}

.cryptosummary-container {
	max-height: calc(100vh - 106px);
}

.auto-scroll {
	overflow-y: auto;
	padding-right: 10px;
}

.auto-scroll::-webkit-scrollbar-thumb {
	background-color: var(--bgDark1);
}

.crypto-list-tabs .ant-list-item,
.wallet-list .ant-list-item {
	border-color: var(--borderLight);
}

.wallet-list .ant-list-item:hover,
.wallet-list .ant-list-item.select,
.addCryptoList li.select {
	background-color: var(--bgBlu);
}

.wallet-list li a,
.crypto-list li a {
	display: inline-flex;
	align-items: center;
	flex-grow: 1;
}

.wallet-list h4 {
	margin-bottom: 0;
}

.crypto-toggle .ant-radio-button-wrapper {
	padding: 22px;
}

.crypto-list-tabs .ant-tabs-tab {
	font-size: 16px;
	margin: 0;
	padding-bottom: 0;
}

.crypto-list-tabs .ant-tabs-tab-btn {
	padding: 3px 33px;
	color: var(--textYellow);
}

.crypto-list-tabs .ant-tabs-tab-btn:focus {
	color: var(--textYellow);
}

.crypto-list-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
	color: var(--textDark) !important;
	background: var(--bgYellow);
	border-radius: 30px;
}

.crypto-list-tabs .ant-tabs-ink-bar {
	display: none;
}

.pop-btn,
.confirm-btn {
	font-size: 14px;
}

.confirm-btn {
	height: 48px;
}

.confirm-btn,
.confirm-btn:hover,
.confirm-btn:focus,
.confirm-btn:active {
	background-image: linear-gradient(99.09deg, var(--btnGrad1), var(--btnGrad2));
	border-radius: 40px;
	text-transform: uppercase;
	font-weight: 700;
	transition: 0.5s ease;
	color: var(--textPureWhite) !important;
	border: 1px solid linear-gradient(99.09deg, var(--btnGrad1), var(--btnGrad2)) !important;
}

.pop-btn {
	background-color: var(--bgYellow);
	border-color: var(--bgYellow);
	border-radius: 40px;
	height: 42px;
	text-transform: uppercase;
	font-weight: 700;
	transition: 0.3s ease;
}

.pop-btn.ant-btn[disabled] {
	background-color: var(--bgDarkYellow) !important;
	border-color: var(--bgDarkYellow) !important;
	color: var(--textDark);
	cursor: not-allowed !important;
}

.pop-btn:hover,
.pop-btn:focus,
.pop-btn:active {
	background-color: var(--bgDarkYellow);
	color: var(--textDark);
	border-color: var(--bgYellow);
}

.pop-cancel {
	background-color: transparent !important;
	color: var(--textWhite30) !important;
	border-color: transparent !important;
	border-radius: 40px;
	height: 57px;
	padding: 0;
}

.pop-cancel:hover span {
	color: var(--textYellow) !important;
	text-decoration: underline;
}

.cancel-btn {
	height: 48px;
	text-transform: uppercase;
	font-weight: 700;
	text-align: center;
}

.custon-btngroup {
	height: 48px;
	text-transform: uppercase;
	border: 0;
	font-weight: 700;
	text-align: center;
	margin-top: 10px;
	width: 50%;
}

.pick-btn {
	border-radius: 0 30px 30px 0;
	background-color: var(--bgYellow);
}

.pick-btn:hover,
.pick-btn:focus,
.pick-btn:active {
	background-color: var(--bgDarkYellow);
	color: var(--textDark);
}

.cancel-btngroup:hover {
	background-color: var(--bgYellow);
	color: var(--textDark);
}

.cancel-btngroup {
	border-radius: 30px 0 0 30px;
	background-color: var(--bgDarkYellow);
	color: var(--textWhite);
}

.crypto-card {
	border-radius: 25px;
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
	background-color: var(--bgDark1);
	background-position: center center;
}

.crypto-card .ant-card-body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.crypto-card.normal-card {
	background-color: var(--bgDark);
	box-shadow: none;
}

.crypto-card.select,
.crypto-card:hover {
	background-image: url(../images/select-card-bg.png);
	background-size: cover;
	border-radius: 1.8rem;
}

.crypto-card.fiatcard {
	background-image: url(../images/fiatcard-bg.png);
	background-size: cover;
	border-radius: 1.8rem;
}

.crypto-card-top {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.textc-white {
	color: var(--textPureWhite);
}

.enter-val-container {
	display: flex;
	align-items: flex-start;
}

.crypto-details {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.crypto-details .crypto-amount {
	text-align: right;
	font-size: 16px;
	font-weight: 200;
	color: #ffffff;
}

.enter-val {
	width: auto;
	border: 1px solid var(--borderLight) !important;
	border-radius: 24px;
	margin-left: 4px;
}

.val-updown {
	position: absolute;
	right: 0;
	top: 0;
	width: 40px;
	height: 40px;
	border-radius: 15px;
	background-color: var(--bgDark);
	text-align: center;
	line-height: 38px;
}

.val-updown:hover .swaparrow,
.swap-updown:hover .swaparrow {
	transform: rotate(180deg);
	transition: 0.5s;
	background-position: -237px -35px;
}

.enter-val input {
	background-color: transparent;
	line-height: normal;
	font-size: 36px;
	color: var(--textWhite30);
	font-weight: var(--fw-300);
}

.enter-val input::placeholder {
	color: var(--textWhite30);
}

.billing-address {
	border: 1px solid var(--borderLight);
	border-radius: 30px;
	padding: 28px 40px;
}

.scanner-img {
	width: 170px;
	height: 170px;
	margin: 30px auto 50px;
	padding: 10px;
	background-color: var(--bgWhite);
	border-radius: 6px;
}

.crypto-address {
	padding: 8px;
	border: 1px solid var(--borderLight);
	border-radius: 12px;
	position: relative;
}

.recomnd-tag {
	background-color: var(--bgBlue);
	border-radius: 3px;
	padding: 1px 5px;
}

/* Sell */
.default-radio {
	display: flex !important;
	justify-content: flex-start !important;
	margin-bottom: 16px;
}

.default-radio .ant-radio-button-wrapper {
	background-color: transparent !important;
	color: var(--textWhite) !important;
	border-radius: 30px;
	min-width: 106px;
	line-height: 32px;
	border: none;
	font-size: 12px;
	font-weight: 500;
	text-align: center;
}

.round-pills .ant-radio-button-wrapper {
	background-color: var(--bgDark);
	height: 26px;
	border-radius: 30px;
	min-width: 106px;
	line-height: 26px;
	color: var(--textYellow);
	border: none;
	font-size: 12px;
	font-weight: 500;
	margin: 0 16px 16px 0;
	text-align: center;
}

.round-pills .ant-radio-button-wrapper-checked,
.default-radio .ant-radio-button-wrapper-checked {
	background-color: var(--bgYellow) !important;
	color: var(--textDark) !important;
}

.round-pills .ant-radio-button-wrapper::before,
.default-radio .ant-radio-button-wrapper::before {
	content: none;
}

.icon-delete {
	width: 35px;
	height: 35px;
	border: 1px solid var(--borderGrey);
	border-radius: 50%;
}

.icon-delete .anticon-delete {
	margin-left: 7px;
	margin-top: 7px;
	font-size: 18px !important;
}

/* Footer */
.footer-links a {
	font-size: 14px;
	color: var(--textWhite30);
	font-weight: var(--fw500);
	display: inline-block;
	margin-right: 30px;
	text-align: center;
}

.footer-links a:hover {
	color: var(--textAqua) !important;
}

.selct-lang,
.selct-lang:hover,
.selct-lang:active,
.selct-lang:focus {
	width: 42px;
	height: 40px;
	border-radius: 50%;
	background-color: var(--bgDarkGreen);
	border: none;
	color: var(--textWhite);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
}

.theme-btn {
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
}

.theme-dark,
.theme-dark:hover,
.theme-dark:focus {
	background-color: var(--bgWhite) !important;
	color: var(--textBlack);
	border-color: var(--bgWhite);
}

/* Coins */
.coin {
	width: 43px;
	height: 43px;
	background: var(--cryptoIcons) no-repeat;
	display: inline-block;
	border-radius: 6px;
}

.coin.DOT {
	background-position: -55px -60px;
}

.coin.BTC {
	background-position: -108px -1px;
}

.coin.XLM {
	background-position: -162px -1px;
}

.coin.usd-d {
	background-position: -216px -1px;
}

.coin.oxbtc {
	background-position: -108px -1px;
}

.coin.act {
	background-position: -270px -1px;
}

.coin.EUR {
	background-position: -378px -1px;
}

.coin.usd {
	background-position: -216px -1px;
}

.coin.eur {
	background-position: -378px -1px;
}

.coin.gbp {
	background-position: -433px -1px;
}

.coin.md {
	width: 40px;
	height: 40px;
	box-shadow: none;
}

.coin.lg {
	width: 52px;
	height: 52px;
	box-shadow: none;
}

.coin-circle {
	width: 52px !important;
	height: 52px !important;
}

.select .coin.lg.btc-white {
	background-position: -155px -769px;
}

.coin.lg.BTC {
	background-position: 5px -767px;
}

.coin.lg.USDC {
	background-position: -753px -768px;
}

.coin.lg.DOT {
	background-position: -155px -769px;
}

.coin.lg.ETH {
	background-position: -47px -767px;
}

.coin.lg.USDT {
	background-position: -287px -768px;
}

.coin.lg.ADA {
	background-position: -218px -768px;
}

.coin.lg.BNB {
	background-position: -358px -768px;
}

.coin.lg.XRP {
	background-position: -423px -768px;
}

.coin.lg.BUSD {
	background-position: -563px -768px;
}

.coin.lg.DOGE {
	background-position: -490px -768px;
}

.coin.lg.GBP {
	background-position: -698px -769px;
}

.coin.lg.USD {
	background-position: -98px -769px;
}

.coin.lg.EUR {
	background-position: -630px -771px;
}

.coin.lg.BCH {
	background-position: -890px -768px;
}

.coin.lg.LINK {
	background-position: -1010px -768px;
}

.coin.lg.UNI {
	background-position: 1px -838px;
}

.coin.lg.COMP {
	background-position: -950px -769px;
}

.coin.lg.XLM {
	background-position: -69px -838px;
}

.coin.lg.LTC {
	background-position: -1076px -769px;
}

.coin.lg.MKR {
	background-position: -1144px -769px;
}

.coin.md.BTC {
	background-position: 5px -404px;
}

.coin.md.USDC {
	background-position: -97px -404px;
}

.coin.md.DOT {
	background-position: -135px -404px;
}

.coin.md.ETH {
	background-position: -47px -404px;
}

.coin.md.USDT {
	background-position: -287px -404px;
}

.coin.md.ADA {
	background-position: -194px -404px;
}

.coin.md.BNB {
	background-position: -314px -404px;
}

.coin.md.XRP {
	background-position: -376px -404px;
}

.coin.md.BUSD {
	background-position: -563px -404px;
}

.coin.md.DOGE {
	background-position: -436px -404px;
}

.coin.md.usd {
	background-position: -79px -1112px;
}

.coin.md.btc-white {
	background-position: 1px -405px;
}

.coin.md.eth-white {
	background-position: -48px -405px;
}

.coin.md.usdt-white {
	background-position: -93px -405px;
}

.coin.md.eur-white {
	background-position: -14px -1112px;
}

.coin.md.visa-white {
	background-position: -15px -1053px;
	width: 56px !important;
	height: 35px !important;
}

.coin.md.visa-black {
	background-position: -150px -1053px;
	width: 56px !important;
	height: 35px !important;
}

.coin.md.mastercard-white {
	background-position: -83px -1055px;
	width: 54px !important;
	height: 35px !important;
}

.coin.credit-white {
	background-position: -488px -2px;
	box-shadow: none;
}

.coin.deposit-white {
	background-position: -542px 0;
	box-shadow: none;
}

.pay-list {
	padding: 16px 0;
	border-bottom: 1px solid var(--borderLight);
}

.expiry-input {
	width: 200px;
}

.expiry-input input {
	width: 50px;
	padding: 0 4px;
	height: 50px;
}

.cust-input,
.expiry-input,
.cust-input .ant-input {
	background: transparent;
	border-radius: 30px;
	height: 50px;
	border: 1px solid var(--borderLight);
	color: var(--textWhite30);
	text-align: center;
	margin-bottom: 6px;
	font-size: 16px;
	padding: 0 16px !important;
}

.cust-input[disabled] {
	background-color: transparent;
	color: var(--textSecondary);
	border-color: inherit !important;
}

.input-label {
	color: var(--textWhite30);
	font-size: 14px;
	font-weight: 200;
	margin-left: 12px;
	margin-bottom: 8px !important;
	display: block;
}

.error-text {
	position: absolute;
	right: 0;
	top: 4px;
	color: var(--textError);
}

.cust-input .ant-select-selector,
.cust-input .ant-input {
	background-color: transparent;
}

.cust-input .ant-select-selection-item,
.cust-input .ant-select-selector,
.cust-input .ant-input,
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
	line-height: 45px !important;
	height: 47px !important;
}

.suisfiat-height .cust-input {
	margin-bottom: 0;
}

/* swap */
.swap {
	border-radius: 25px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 24px;
	min-height: 105px;
}

.swapfrom-card {
	background-color: var(--bgSwapFrom);
	border-bottom-right-radius: 0 !important;
}

.swapreceive-card {
	background-color: var(--bgSwapTo);
	border-top-right-radius: 0 !important;
}

.card-input {
	padding: 4px 0 !important;
	font-size: 24px;
	line-height: 24px;
	color: var(--textPureWhite);
}

.icon.swapfrom-arrow {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 43px;
	height: 26px;
	background-position: -54px -288px;
}

.icon.swapto-arrow {
	position: absolute;
	top: 0;
	right: 0;
	width: 43px;
	height: 26px;
	margin-right: 12px;
	background-position: -3px -287px;
}

.crypto-search.ant-input-search .ant-input-group input {
	background-color: var(--bgDark) !important;
	height: 38px;
	color: var(--textWhite30);
	padding-left: 18px;
	border: 0;
	border-radius: 30px 0px 0 30px;
}

.crypto-coin {
	width: 63px;
	margin-right: 20px;
}

.crypto-search.ant-input-search .ant-input-group .ant-input-group-addon {
	background-color: var(--bgDark) !important;
	border-radius: 0 20px 20px 0;
}

.crypto-search .anticon-search {
	font-size: 18px;
	color: var(--textWhite);
	margin-right: 12px !important;
}

.confirm-icon {
	width: 124px;
	height: 124px;
	margin-bottom: 50px;
}

.loader {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	font-size: 36px;
	color: var(--textWhite);
	background-color: var(--bgDarkGrey);
	justify-content: center;
}

.select-drpdwn {
	background-color: var(--bgGloom);
	margin-right: 30px;
	padding: 24px 0;
	border-radius: 10px;
}

.select-drpdwn .ant-select-item {
	padding: 10px 24px;
	color: var(--textWhite);
}

.select-drpdwn .ant-select-item:hover,
.select-drpdwn .ant-select-item.ant-select-item-option-active,
.select-drpdwn .ant-select-item.ant-select-item-option-selected,
.select-drpdwn .ant-select-item.ant-select-item-option-active.ant-select-item-option-selected {
	background-color: var(--bgDarkGrey);
}

.cust-input.ant-select {
	padding: 0 !important;
}

.wallet-title {
	font-size: 16px;
	text-transform: uppercase;
	color: var(--textWhite30);
	margin-bottom: 0;
	font-weight: 600;
}

.crypto-list .ant-list-item-meta,
.wallet-list .ant-list-item-meta {
	display: flex;
	align-items: center;
}

.crypto-list .ant-list-item,
.wallet-list .ant-list-item {
	padding: 5px 0 0 2px !important;
}

.recomoned-bg {
	background: var(--texthover);
	font-size: 12px;
	padding: 0 2px;
	line-height: 1.2;
	color: var(--textWhite);
	float: right;
}

.text-width {
	width: 250px;
}

.pop-drpdwn-toogle {
	background: var(--bgDark);
	width: 50px;
	height: 50px;
	border-radius: 20px;
	text-align: center;
	line-height: 50px;
}

.alert-popup li {
	padding: 0;
}

.alert-popup {
	background: #e7f2e1;
	border-radius: 4px;
	border-radius: 6px;
}

.alert-popup .ant-card-body {
	padding: 12px;
}

.alert-popup .alert-close svg {
	color: #000;
}

.alert-success {
	width: 20px;
	height: 20px;
}

.close-icon {
	background-position: -143px -35px;
}

.find-wallet .ant-select-selector {
	height: 50px !important;
	border: 1px solid var(--borderLight);
	border-radius: 30px !important;
	background: transparent !important;
	color: #fff;
	padding: 0 20px !important;
}

.find-wallet .ant-select-selection-item {
	line-height: 3.5 !important;
}

.find-wallet .ant-select-arrow {
	color: var(--bgWhite);
	margin-right: 5px;
}

.ant-checkbox-checked .ant-checkbox-inner {
	border: 1px solid var(--bgDarkYellow);
	border-color: var(--bgDarkYellow);
	background-color: var(--bgDarkYellow);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner,
.ant-checkbox-checked::after {
	border: 1px solid var(--textSecondary);
	border-color: var(--textSecondary);
}

.agree-check input[type="checkbox"] {
	display: none;
}

.agree-check input[type="checkbox"]+span {
	display: inline-block;
	width: 34px;
	height: 34px;
	vertical-align: text-top;
	cursor: pointer;
	border: 1px solid var(--borderLight);
}

.agree-check input[type="checkbox"]:checked+span {
	background: var(--lightThemeIcons);
	background-position: -33px -109px;
	border-color: transparent !important;
}

.agree-check label {
	height: 34px;
}

.ant-select-arrow {
	color: var(--textWhite);
	right: 16px;
	top: 24px;
}

.custom-switch {
	border-bottom: none;
}

.agree .ant-form-item-explain-error {
	text-align: left;
}

#memberWalletId .ant-select-selector,
#currency .ant-select-selector {
	border-radius: 25px !important;
	height: 49px !important;
}

#memberWalletId .ant-form-item-has-error,
#currency .ant-form-item-has-error {
	border: 1px solid #ff4d4f !important;
}

.custom-formcard {
	width: 500px;
	margin: 0px auto;
	background: #515a64;
	border-radius: 50px;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 50px;
	padding-bottom: 50px;
}

.ant-form-item-has-error .cust-input {
	background-color: transparent !important;
	border-color: #ff4d4f !important;
}

/* .ant-form-item-explain-error {
	text-align: right;
} */

.ant-input-suffix,
.anticon-eye-invisible.ant-input-password-icon {
	color: var(--bgWhite);
}

.coin.XRP {
	background-position: -2px -60px;
}

.coin.USDT {
	background-position: -596px -1px;
}

.coin.eth {
	background-position: -55px -1px;
}

.coin.btc {
	background-position: -108px -1px;
}

.coin.dat {
	background-position: -323px -1px;
}

.coin.DAT {
	background-position: -323px -1px;
}

.coin.ETH {
	background-position: -55px -1px;
}

.coin.OXBTC {
	background-position: -596px -180px;
}

.coin.BNB {
	background-position: -653px -1px;
}

.coin.ADA {
	background-position: -702px -1px;
}

.coin.USDC {
	background-position: -649px -57px;
}

.coin.DOGE {
	background-position: -756px -1px;
}

.coin.BUSD {
	background-position: -378px -60px;
}

.coin.UNI {
	background-position: -702px -60px;
}

.coin.LTC {
	background-position: -108px -60px;
}

.coin.SOL {
	background-position: -596px -60px;
}

.coin.LINK {
	background-position: -544px -60px;
}

.coin.MATIC {
	background-position: -488px -60px;
}

.coin.THETA {
	background-position: -435px -60px;
}

.coin.DAI {
	background-position: -2px -120px;
}

.coin.VET {
	background-position: -323px -60px;
}

.coin.FIL {
	background-position: -216px -60px;
}

.coin.ALGO {
	background-position: -435px -120px;
}

.coin.CDAI {
	background-position: -2px -180px;
}

.coin.LUNA {
	background-position: -55px -180px;
}

.coin.AAVE {
	background-position: -216px -120px;
}

.coin.SHIB {
	background-position: -164px -120px;
}

.coin.TRX {
	background-position: -163px -60px;
}

.coin.XMR {
	background-position: -56px -120px;
}

.coin.OKB {
	background-position: -269px -120px;
}

.coin.EOS {
	background-position: -109px -120px;
}

.coin.CETH {
	background-position: -434px -180px;
}

.coin.CAKE {
	background-position: -598px -120px;
}

.coin.LEO {
	background-position: -164px -183px;
}

.coin.ATOM {
	background-position: -488px -120px;
}

.coin.MKR {
	background-position: -702px -116px;
}

.coin.BSV {
	background-position: -544px -120px;
}

.coin.COMP {
	background-position: -164px -303px;
}

.coin.NEO {
	background-position: -323px -120px;
}

.coin.AVAX {
	background-position: -108px -183px;
}

.coin.MIOTA {
	background-position: -756px -60px;
}

.coin.HBAR {
	background-position: -378px -180px;
}

.coin.KSM {
	background-position: -378px -120px;
}

.coin.HT {
	background-position: -216px -183px;
}

.coin.DASH {
	background-position: -323px -303px;
}

.coin.BTT {
	background-position: -268px -183px;
}

.coin.TUSD {
	background-position: -863px -303px;
}

.coin.NEXO {
	background-position: -811px -120px;
}

.coin.HOT {
	background-position: -863px -240px;
}

.coin.PAX {
	background-position: -810px -240px;
}

.coin.RUNE {
	background-position: -756px -120px;
}

.coin.ZIL {
	background-position: -920px -3px;
}

.coin.ARRR {
	background-position: -543px -180px;
}

.coin.BAL {
	background-position: -325px -240px;
}

.coin.GAS {
	background-position: -323px -120px;
}

.coin.GUSD {
	background-position: -864px -3px;
}

.coin.BAND {
	background-position: -378px -240px;
}

.coin.PAc {
	background-position: -543px -180px;
}

.coin.DOCK {
	background-position: -378px -303px;
}

.coin.GRIN {
	background-position: -864px -120px;
}

.coin.GTO {
	background-position: -651px -180px;
}

.coin.SAI {
	background-position: -268px -305px;
}

.coin.STORM {
	background-position: -542px -305px;
}

.coin.AEON {
	background-position: -757px -180px;
}

.coin.GSC {
	background-position: -864px -60px;
}

.coin.COLX {
	background-position: -108px -303px;
}

.coin.BTDX {
	background-position: -757px -240px;
}

.coin.DROP {
	background-position: -434px -303px;
}

.coin.BSD {
	background-position: -598px -240px;
}

.coin.ARG {
	background-position: -53px -240px;
}

.coin.STPL {
	background-position: -216px -305px;
}

.coin.DTH {
	background-position: -433px -305px;
}

.coin.TBX {
	background-position: -812px -305px;
}

.coin.SPARTA {
	background-position: -2px -240px;
}

.coin.POW {
	background-position: -811px -180px;
}

.coin.CMM {
	background-position: -57px -305px;
}

.coin.ENTRP {
	background-position: -599px -305px;
}

.coin.AMP {
	background-position: -268px -60px;
}

.coin.TFUEL {
	background-position: -652px -120px;
}

.coin.OXY {
	background-position: -487px -240px;
}

.coin.BCH {
	background-position: -1px -3px;
}

.ant-spin-dot-item {
	background-color: var(--bgWhiteSpin);
}

.cust-input .ant-input {
	text-align: left !important;
	padding: 0 !important;
	background-color: transparent !important;
}

.icon.lg.usd-default {
	background-position: -1px -70px;
	width: 32px !important;
}

.icon.lg.swap-arrow {
	background-position: -8px -186px;
}

.to-receive {
	text-transform: uppercase;
	font-size: 14px;
	text-align: center;
	color: var(--textWhite30);
	font-weight: 500;
	margin-bottom: 16px !important;
	margin-top: 0px;
}

.swap-updown {
	position: absolute;
	right: 0;
	bottom: -17px;
	width: 40px;
	height: 40px;
	z-index: 1;
	margin-right: 20px;
	border-radius: 15px;
	background-color: var(--bgDark);
	text-align: center;
	line-height: 38px;
}

.swap-updown:hover {
	background: var(--bgDark);
}

.default-circle {
	border-radius: 50%;
	padding: 12px 4px;
}

.swap-from {
	background-color: var(--bgSwapTo);
}

.swap-to {
	background-color: var(--bgSwapFrom);
}

.side-drawer-full .ant-table-cell {
	border-color: var(--borderLight);
	color: var(--textWhite);
	vertical-align: top;
}

.bg-color {
	padding: 8px 6px;
	background: #ffffff1c;
	border-radius: 6px;
}

.side-drawer-full .ant-table-cell:nth-child(2) .ant-table-column-title {
	width: 105px;
}

.side-drawer-full .ant-table-cell:nth-child(3) .ant-table-column-title {
	width: 100px;
}

.side-drawer-full tr th {
	border: none;
}

.side-drawer-full tr th.ant-table-cell-scrollbar {
	box-shadow: none;
}

.side-drawer-full .ant-table-tbody p.fs-16 {
	line-height: normal;
}

.side-drawer-full .ant-pagination-disabled .ant-pagination-item-link,
.side-drawer-full .ant-pagination-disabled:hover .ant-pagination-item-link,
.side-drawer-full .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
	color: rgb(255 251 251 / 25%);
	background-color: transparent;
}

.side-drawer-full .ant-pagination-item-active {
	border-color: #ffffff !important;
	background: transparent;
}

.side-drawer-full .ant-pagination-item-active a,
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
	color: #ffdb1a !important;
}

.side-drawer-full .ant-table-column-title {
	text-transform: uppercase;
}

.custom-table .ant-table-column-title {
	color: var(--textWhite);
	z-index: 0;
}

.custom-table .ant-table-body,
.custom-table .ant-table-thead>tr>th {
	background: var(--bgDarkGrey);
}

.custom-table .ant-table-row.ant-table-row-level-0:hover td,
td.ant-table-column-sort {
	background: transparent;
}

.coin-typo .gain,
.coin-typo .lose {
	transform: scale(0.85);
}

.custom-table .ant-table-tbody>tr:first-child td {
	padding: 0 !important;
}

.custom-table .ant-table-thead th.ant-table-column-has-sorters:hover {
	background: var(--bgGrey);
}

.custom-table .ant-table-tbody>tr>td {
	border-bottom: 1px solid var(--borderDark) !important;
	padding: 12px 0;
}

.custom-table .ant-table-tbody>tr>td:first-child {
	padding-left: 12px;
}

.ant-table-tbody>tr>td,
.custom-table .ant-table-thead>tr>th {
	border-bottom: 1px solid #313c46 !important;
	transition: background 0.3s;
}

.markets-panel .custom-table .ant-table-thead>tr>th {
	border-bottom: none !important;
}

.custom-table .ant-table-row:first-child td {
	padding: 0 16px 16px !important;
}

.custom-table .ant-table-container table>thead>tr:first-child th:first-child {
	border-top-left-radius: 0;
}

.custom-table .ant-table-container table>thead>tr:first-child th {
	border: none;
	padding: 16px 4px;
}

.ant-table-container table>thead>tr:first-child th:last-child {
	border-top-right-radius: 0;
}

.custom-table .ant-table-cell-scrollbar {
	box-shadow: none !important;
}

.custom-table tr>.ant-table-row-level-0:first-child td {
	border-bottom: 0 !important;
}

.widthdraw-pop .ant-modal-close-x {
	position: absolute;
	width: 22px;
	height: 22px;
	line-height: 22px;
	top: 16px;
	right: 16px;
}

.markets-panel .ant-table-tbody>tr.ant-table-placeholder:hover>td {
	background: var(--bgGrey) !important;
}

.markets-panel .ant-table-tbody>tr.ant-table-placeholder>td {
	border-bottom: none !important;
}

.markets-popup .custom-table .ant-table-body {
	padding: 0 0 0 24px !important;
}

.market-actions {
	width: 65px;
}

.user-profile {
	border-radius: 50%;
	background: var(--bgDarkGrey);
	object-fit: cover;
}

.profile-dropdown .user-profile {
	width: 112px;
	height: 112px;
	border-radius: 50%;
	display: block;
	margin: 0 auto;
}

.profile-dropdown {
	text-align: center;
	padding: 12px 34px;
	
}

.profile-dropdown .drpdwn-list {
	padding-top: 0px;
	padding-left: 0;
	padding-right: 0;
	margin-left: -35px;
	margin-right: -35px;
}

.profile-btn,
.profile-btn:hover,
.profile-btn:active,
.profile-btn:focus {
	background: var(--bgDarkGrey);
	border: 1px solid #ed4b9e;
	height: 38px;
	border-radius: 19px;
	color: var(--textWhite);
	text-transform: uppercase;
	font-size: 12px;
	font-weight: normal;
	margin: 0 0 24px;
	padding: 10px 14px;
	width: 200px;
}

.user-labels {
	padding: 12px;
	font-size: 16px;
	font-weight: 600;
	color: var(--textWhite);
	margin-bottom: 0;
	margin-left: 8px;
}

.user-list {
	list-style: none;
}

.user-labels.active,
.user-labels:hover {
	background: var(--bgDarkYellow);
	font-weight: 700;
	border-radius: 30px;
	padding: 12px;
	color: var(--textDark);
}

.profile-info {
	width: 180px;
	height: 180px;
	padding: 5px;
	border-radius: 50%;
	margin: 0 auto 24px;
	background-image: linear-gradient(128deg,
			var(--bgDark1) 50%,
			var(--bgDark1) 50%);
	position: relative;
}

.profile-info .user-profile {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	padding: 6px;
	background-color: var(--bgDarkGrey);
	object-fit: cover;
}

.profile-info .img-upld {
	position: absolute;
	right: -5px;
	bottom: 20px;
	background-color: var(--bgYellow);
	border-color: var(--bgYellow);
}

.basic-info,
.contact-info {
	padding: 24px;
}

.basicinfo {
	font-weight: 600 !important;
	color: var(--textWhite30) !important;
	line-height: 28px !important;
}

.basic-decs {
	font-size: 16px;
	font-weight: 400;
	color: var(--textWhite30);
}

.profile-label {
	font-size: 14px;
	color: var(--textWhite30);
	font-weight: 400;
}

.profile-value {
	font-size: 16px;
	color: var(--textWhite30);
	font-weight: 700;
	margin-left: 8px;
}

.profileinfo {
	border-top: 1px solid var(--borderGrey);
}

.profileinfo:first-child {
	border-top: 0;
}

.profileinfo.active,
.profileinfo:hover {
	background: var(--bgOrient1);
	margin-left: -32px;
	margin-right: -32px;
}

.profileinfo.active .profile-block,
.profileinfo:hover .profile-block {
	padding: 0 32px;
}

.cust-select {
	min-width: 250px;
}

.cust-select .ant-select-selection-placeholder {
	color: #ed4b9e !important;
}

.border-bottom {
	border-bottom: 1px solid var(--borderGrey);
}

.theme-switch {
	width: 212px;
	background: var(--bgWhite30);
	border-radius: 30px;
	height: 52px;
	padding: 7px 10px;
}

.theme-switch.theme-active {
	background: var(--bgDark);
}

.themeSwitchOn {
	background: #0e0e2c;
}

.themeSwitchOn .theme-txt {
	color: #fff;
}

.switch-circle {
	width: 39px;
	height: 39px;
	border-radius: 50%;
	padding: 8px;
	background: #e9f3ff;
}

.theme-active .switch-circle {
	background: var(--borderGrey);
}

.themeSwitchOn .switch-circle {
	background: #313c46;
}

.theme-txt {
	font-size: 14px;
	font-weight: 500;
	color: var(--textDark);
}

.theme-active .theme-txt {
	color: var(--textWhite);
	font-weight: 400;
}

.custom-forminput .cust-input .ant-input {
	text-align: center !important;
}

.user-list .ant-tabs-nav {
	width: 265px;
}

.user-list .ant-tabs-tab {
	font-size: 16px;
	font-weight: 600;
	color: var(--textWhite);
	margin-bottom: 0;
	padding: 12px !important;
}

.user-list.ant-tabs-left>.ant-tabs-content-holder {
	border-left: 0 !important;
}

.user-list .ant-tabs-content.ant-tabs-content-left {
	padding-left: 65px;
}

.user-list .ant-tabs-tab.ant-tabs-tab-active ,
.user-list .ant-tabs-tab:hover {
	background: var(--bgDarkYellow);
	font-weight: 600;
	border-radius: 30px;
	padding: 12px;
	color: var(--textDark);
}

.user-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: var(--textDark);
}

.user-list .ant-tabs-ink-bar {
	background: transparent !important;
}

.addressbook_tabs .ant-tabs-nav {
	width: auto !important;
}

.addressbook_tabs .ant-tabs-tab.ant-tabs-tab-active,
.addressbook_tabs .ant-tabs-tab:hover {
	background: transparent !important;
	font-weight: 600;
	padding: 12px !important;
	color: var(--textDark);
}

.wdr-summary {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.selectcustom-input .ant-select:not(.ant-select-customize-input) .ant-select-selector {
	background-color: transparent;
	border: transparent;
	cursor: pointer;
}

.customicon {
	position: absolute;
	left: 0px;
	top: 4px;
	margin-left: 67px;
}

.defaulticon {
	position: absolute;
	left: 0px;
	top: 3px;
	margin-left: 110px;
}

.ant-modal-content {
	border-radius: 24px;
}

.ant-modal-header {
	border-radius: 24px 24px 0 0;
	padding: 16px 24px;
	border-color: var(--borderGrey);
}

.ant-modal-header,
.ant-modal-content {
	background-color: var(--bgGrey);
}

.ant-modal-title {
	font-size: 14px;
	line-height: 34px;
	color: var(--textWhite30);
	text-transform: uppercase;
}

.ant-modal-body p {
	font-size: 16px;
	color: var(--textWhite50);
	font-weight: 400;
}

.ant-modal-footer {
	padding: 16px 24px;
	border-color: var(--borderGrey);
}

.cust-adon .ant-input-group,
.cust-adon .ant-input-group .ant-input:focus,
.cust-adon .ant-input,
.cust-adon .ant-input-group-addon {
	border: none !important;
	box-shadow: none;
	padding: 0;
	background: transparent;
}

.cust-adon .ant-input {
	line-height: 45px !important;
	height: 47px !important;
	background-color: transparent !important;
	padding: 0 1px !important;
	margin-bottom: 0;
}

.ant-drawer {
	z-index: 99 !important;
}

.ant-picker-input>input,
.ant-form-item-label>label {
	color: var(--textWhite30);
}

.search-bg {
	border: 1px solid rgba(0, 0, 0, 0.08);
	color: #313c46 !important;
	background-color: var(--bgDarkGrey) !important;
	padding: 15px 10px;
}

.anticon-copy svg,
.anticon-check svg {
	color: var(--textYellow);
}

.k-pager-numbers .k-link.k-state-selected,
.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected,
.k-list .k-item.k-state-selected:hover,
.k-list-optionlabel.k-state-selected:hover {
	color: var(--textDark);
	background-color: var(--bgYellow);
}

.k-pager-numbers .k-link {
	color: var(--textDark);
}

.k-button,
.k-textbox,
.k-pager-numbers .k-link,
.k-pager-numbers .k-link.k-state-selected {
	border-radius: 30px;
}

.k-i-sort-asc-sm::before,
.k-i-sort-desc-sm::before,
.k-i-arrow-60-left::before,
.k-i-arrow-60-right::before,
.k-i-arrow-end-left::before,
.k-i-arrow-end-right::before {
	color: var(--textDark);
}

.k-button.k-primary.excel-btn {
	background: var(--bgYellow);
	color: var(--textDark);
	border-color: var(--bgYellow);
	padding: 10px 24px;
	font-weight: 500;
	text-transform: uppercase;
}

.k-button.k-primary {
	background: var(--bgYellow);
	color: var(--textDark);
	border-color: var(--bgYellow);
	padding: 4px 24px;
	font-weight: 500;
	text-transform: uppercase;
}

.k-button.k-primary:active,
.k-button.k-primary:hover {
	background: var(--bgDarkYellow);
	color: var(--textDark);
	border-color: var(--bgDarkYellow);
}

.k-grid thead {
	background: var(--bgDarkGrey);
}

.k-i-more-vertical.k-icon {
	width: 20px;
	height: 20px;
	background-position: -350px -1px;
	transform: scale(0.9);
}

.k-grouping-row .k-i-collapse::before,
.k-grouping-row .k-i-expand::before,
.k-i-more-vertical:before {
	display: none;
}

.ant-picker-suffix {
	color: var(--textWhite);
}

.k-pager-sizes .k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap,
.k-pager-sizes .k-dropdown .k-dropdown-wrap:hover {
	background: var(--bgDarkGrey);
	color: var(--textWhite);
}

.k-pager-nav.k-link:hover {
	background-color: var(--bgDarkGrey);
	border-radius: 50%;
}

.fiatdep-info .anticon-copy,
.fiatdep-info .anticon-check {
	font-size: 16px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	background: transparent !important;
	border: 0;
}

/*--Documents--*/
.notify {
	padding: 16px;
	margin-bottom: 36px;
}

.notify .ant-alert-message {
	font-size: 20px !important;
	font-weight: 700;
	color: var(--textDark) !important;
}

.notify .ant-alert-action {
	margin: 15px 30px 0 30px;
}

.notify .ant-alert-action .ant-btn {
	color: #0038ff;
	font-weight: 500 !important;
}

.notify .ant-alert-close-icon {
	font-size: 18px;
	color: var(--textDark) !important;
}

.ant-notification-notice .ant-notification-close-icon {
	color: var(--textWhite30);
}

.ant-notification {
	direction: ltr;
}

.ant-notification-notice-closable .ant-notification-notice-message {
	padding-left: 0 !important;
}

.ant-notification-rtl .ant-notification-notice-close {
	left: auto;
	right: 15px;
}

.ant-notification-notice-message {
	color: var(--textWhite30) !important;
}

.ant-notification-notice-description {
	color: var(--textSecondary) !important;
}

.ant-notification-notice {
	background-color: var(--bgDarkGrey);
	border-radius: 10px;
	font-size: 14px;
}

.bankview-right {
	padding: 34px 34px 0 !important;
	background-color: var(--bgDarkGrey);
	border-radius: 30px;
}

.bankview-right .kpi-val {
	padding-bottom: 34px;
}

.kpi-List .ant-col {
	display: flex;
	margin-bottom: 34px;
}

.kpi-List .icon {
	margin: 6px 10px 0 0;
}

.kpi-List .icon+div {
	flex: 1;
}

.kpi-List .kpi-label {
	font-size: 12px;
	color: var(--textWhite50);
}

.kpi-List .kpi-val {
	font-size: 14px;
	font-weight: 500;
	color: var(--textWhite30);
	word-break: break-word;
	margin-right: 12px;
}

.kpi-item .kpi-val {
	margin-left: 0;
}

.accordian .ant-collapse-item:last-child>.ant-collapse-content,
.accordian .ant-collapse-item {
	border-color: var(--borderGrey);
	background-color: var(--bgDarkGrey);
}

.accordian .ant-collapse-header {
	border-radius: 20px !important;
	font-size: 18px;
	font-weight: 600;
	background-color: var(--bgOrion);
	color: var(--textWhite30) !important;
	padding: 16px 20px 16px 24px !important;
}

.accordian {
	border-color: var(--bgOrion) !important;
	border-radius: 24px;
}

.accordian .ant-collapse-header .icon.downangle {
	position: relative;
	margin-right: 34px !important;
}

.accordian .ant-collapse-header .icon.downangle::after {
	content: "";
	position: absolute;
	right: -16px;
	top: 0;
	border-right: 1px solid var(--borderGrey);
	height: 20px;
}

.accordian .ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
	border-radius: 20px 20px 0 0 !important;
}

.accordian .ant-collapse-arrow {
	vertical-align: text-bottom !important;
}

.accordian .ant-collapse-item.ant-collapse-item-active .downangle {
	background-position: -329px 0;
}

.accordian .ant-collapse-content-box {
	padding: 20px;
	background-color: var(--bgDarkGrey);
	border-radius: 0 0 24px 24px;
	border-bottom: 1px solid var(--bgOrion);
}

.upload {
	border-radius: 30px !important;
	background-color: var(--bgGloom) !important;
	border-color: var(--borderGrey) !important;
}

.upload .ant-upload {
	padding: 36px 0 !important;
}

.upload .ant-upload-text {
	font-weight: 600;
	color: var(--textWhite30) !important;
}

.docfile {
	background-color: var(--bgGloom);
	border-radius: 20px;
	padding: 16px;
	display: flex;
	align-items: center;
	margin: 12px 16px 0 0;
	flex: 1 0 240px;
	width: 200px;
}

.docdetails {
	flex: 1;
	margin-right: 16px;
}

.docdetails .docname {
	font-weight: 700;
	color: var(--textWhite30);
	text-transform: uppercase;
	line-height: 16px;
	max-width: 150px !important;
}
.docdetails .docnames{font-weight: 700;color: var(--textWhite30); line-height: 16px;max-width: 150px !important;}

.docfile-container {
	display: flex;
	flex-wrap: wrap;
}

.reply-container {
	display: flex;
	margin-bottom: 24px;
}

.reply-container .user-shortname {
	width: 46px;
	height: 46px;
	border-radius: 50%;
	background-color: #64869b;
	margin-right: 16px;
	color: #ffffff;
	font-size: 18px;
	text-align: center;
	line-height: 46px;
	font-weight: 500;
	text-transform: uppercase;
}

.reply-body {
	flex: 1;
}

.reply-txt {
	font-size: 16px;
	color: var(--textWhite50);
	font-weight: 200;
	margin-bottom: 0;
	word-break: break-all;
}

.reply-date {
	font-size: 12px;
	color: var(--textSecondary);
}

.reply-username {
	font-size: 14px;
	color: var(--textWhite30);
	font-weight: 600;
	display: inline-block;
	margin-right: 10px;
}

.send-circle {
	background: var(--bgYellow);
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	border-radius: 50%;
}

.chat-send {
	display: flex;
	border: 1px solid var(--borderLight);
	padding: 12px;
	border-radius: 20px;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
}

.chat-send input {
	border: 0;
	flex: 1;
	font-size: 16px;
	color: var(--textWhite30);
	font-weight: 200;
}

.chat-send input:focus {
	outline: none;
}

.preview-file {
	text-align: center;
}

.preview-file img {
	max-width: 100%;
}

.react-pdf__Page__canvas {
	margin: auto;
}

.ant-select-focused .ant-select-selector {
	box-shadow: none !important;
}

.selectcustom-input .anticon-search {
	display: none;
}

.custom-add-select {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.new-add {
	border: 1px solid #899097;
	padding: 14px;
	border-bottom-right-radius: 30px;
	border-top-right-radius: 30px;
	border-left: 0;
}

/* .transaction-tabs .k-grid-content{overflow-y: auto;} */
.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
	display: inline-block;
	margin-right: 4px;
	color: var(--textWhite30) !important;
	font-size: 14px;
	font-family: SimSun, sans-serif;
	line-height: 1;
	content: "*";
	opacity: 0.6;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
	content: none;
}

.custom-label .ant-form-item-label>label::after {
	color: transparent !important;
}

.custom-label .ant-form-item-label,
.custom-label.ant-row {
	display: block !important;
}

.custom-label .ant-form-item-label {
	text-align: left;
	color: var(--textWhite30) !important;
	font-size: 14px;
	font-weight: 200;
	margin-left: 12px;
	margin-bottom: 4px !important;
}

.cust-adon .ant-input.ant-input-disabled {
	cursor: default;
}

.staus-lbl {
	display: inline-block;
	color: var(--textPureWhite);
	background-color: var(--bgGreen);
	font-size: 12px;
	padding: 2px 8px;
	border-radius: 4px;
	font-weight: 500;
}

.approved {
	background-color: var(--bgGreen);
}

.rejected {
	background-color: #ff0000;
}

.submitted {
	background-color: #0038ff;
}

.requested {
	background-color: #e0bb02;
}

.wmy-graph .ant-radio-button-wrapper {
	border: none;
	background: none;
	color: var(--textYellow);
	font-weight: 600;
}

.wmy-graph .ant-radio-button-checked {
	background-color: var(--bgYellow);
	border-color: var(--bgYellow) !important;
	border-radius: 16px;
}

.wmy-graph .ant-radio-button-wrapper:not(:first-child)::before {
	content: none;
}

.wmy-graph .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
	background: none;
}

.wmy-graph .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	background: none;
	color: var(--textDark) !important;
}

.custom-checkbox input[type="checkbox"] {
	display: block;
	position: absolute;
	zoom: 1.5;
	margin: 0;
	opacity: 0;
	cursor: pointer;
}

.custom-checkbox input[type="checkbox"]+span {
	width: 20px;
	height: 20px;
	cursor: pointer;
	background-position: -304px -71px;
}

.custom-checkbox input[type="checkbox"]:checked+span {
	width: 20px;
	height: 20px;
	cursor: pointer;
	background-position: -271px -71px;
}

.gridLink {
	color: #e0bb02;
	cursor: pointer;
	display: inline-block;
}

.ant-message {
	bottom: 15px !important;
	top: auto;
}

.custom-msg .ant-message-notice-content {
	background: #333;
	color: var(--textPureWhite);
	padding: 20px 40px;
	min-width: 200px;
}

.docreq-slider .slick-dots li button {
	width: 30px;
	height: 30px;
	color: var(--textWhite30);
	font-size: 16px;
	border-radius: 15px;
}

.docreq-slider .slick-dots li {
	width: 30px;
}

.coin-select {
	position: absolute;
	right: 13px;
	bottom: 13px;
}

.ant-modal-close-x {
	cursor: default;
}

.addCryptoList {
	list-style: none;
	padding-left: 0;
	max-height: calc(100vh - 188px);
	overflow-y: auto;
}

.addCryptoList li {
	padding: 10px;
	color: var(--textWhite);
	border-bottom: 1px solid var(--borderLight);
	font-size: 16px;
}

.addCryptoList li:hover {
	background-color: var(--bgBlu);
	cursor: pointer;
}

.tinycontent {
	word-break: break-all;
	max-height: 100px;
	height: 100px;
	overflow: hidden;
}

.carousel-card:hover .tinycontent {
	overflow-y: auto;
}

.documentmodal-width .preview-pdf-page {
	border: 1px solid var(--bgDark);
	margin-bottom: 10px;
}

.accordian textarea.ant-input:placeholder-shown {
	line-height: 48px;
}

.accordian textarea.ant-input {
	min-height: 48px !important;
	padding-top: 0px !important;
}

.coin-details {
	background-color: var(--bgGloom);
}

.coin-details-spin {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 400px;
}

.coin-info {
	padding: 16px 0;
	border-top: 1px solid var(--borderGrey);
	font-size: 16px;
	display: flex;
	justify-content: space-between;
}

.coin-info span {
	font-size: 16px;
	color: var(--textWhite30);
	font-weight: 600;
}

.coin-info>span:first-child {
	font-size: 14px;
	color: var(--textWhite50);
	font-weight: 200;
}

.trade-graph .ant-radio-button-wrapper {
	background: none;
	color: var(--textSecondary);
	font-weight: 500;
	border-color: var(--borderLight);
	overflow: hidden;
}

.trade-graph .ant-radio-button-wrapper:first-child {
	border-radius: 8px 0 0 8px;
}

.trade-graph .ant-radio-button-wrapper:last-child {
	border-radius: 0 8px 8px 0;
}

.trade-graph .ant-radio-button-checked {
	background-color: var(--bgYellow);
	border-color: var(--bgYellow) !important;
}

.trade-graph .ant-radio-button-wrapper:not(:first-child)::before {
	content: none;
}

.trade-graph .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
	background: none;
}

.trade-graph .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	background: none;
	color: var(--textDark) !important;
}

.coin-bal {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.coin-bal ul li {
	display: inline-block;
	list-style: none;
	font-size: 10px;
	text-align: center;
	color: var(--textWhite30);
	font-weight: 500;
	margin: 0 10px;
}

.otp-verify {
	position: relative;
	margin-left: 0 !important;
}

.otp-verify button {
	color: var(--textYellow) !important;
	font-weight: 500;
	padding: 0 6px 0 0;
}

.otp-verify .ant-form-item-label {
	width: auto;
	position: absolute;
	right: 4px;
	top: 10px;
	z-index: 1;
}

.otp-verify .ant-form-item-label>label::after {
	content: none !important;
}

.coin-bal ul li div {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: var(--bgGloom);
	margin: 0 auto 6px;
	line-height: 38px;
	box-shadow: 0 3px 16px rgba(0, 0, 0, 0.15);
}

.walletsdrawer .mobile-list {
	margin: 0;
}

.wallet-head {
	padding: 8px 16px;
	background-color: var(--bgDarkGrey);
	border-radius: 10px;
	margin-bottom: 6px;
}

.crypto-wallets .ant-list-item-meta-content {
	width: auto;
}

.minmax {
	position: absolute;
	top: 4px;
	right: 10px;
}

.min-btn {
	background-color: var(--bgYellow) !important;
	margin-left: 8px;
	border-radius: 10px;
	font-size: 12px;
	font-weight: 500;
	height: 20px;
}

.min-max-btn label {
	width: 100%;
}

.side-drawer .coin-info:last-child {
	margin-bottom: 60px;
}

/*----Landing Page----*/
.wlecome-text {
	font-size: 74px;
	font-weight: 600;
	color: var(--textWhite30) !important;
	background: -webkit-linear-gradient(180deg,
			#ff4d76 10%,
			#a076cf 20%,
			#6385ff 20%,
			#23b9ff 10%,
			#9cfacc);
	background-clip: text;
	-webkit-text-fill-color: transparent;
}

.suissebgText {
	background-image: url(../images/Group\ 97.png);
	background-repeat: no-repeat;
	background-size: contain;
	height: 400px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.header-block {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.landing_page .ftwallets .coin {
	border-radius: 50%;
}

.ftwallets .ant-list-item {
	padding: 16px 0;
	border-color: #636363;
}

.cards-block {
	background-color: var(--bgYellow);
	border-radius: 24px;
	padding: 24px 30px;
}

.cards-block .ant-list-item {
	border-color: #636363;
	align-items: flex-end;
}

.cards-block .ant-list-item:last-child {
	padding-bottom: 0;
}

.fx-block,
.crypto-block {
	padding: 24px 30px;
}

.fx-block .ant-list-item,
.fx-block .ant-list-header {
	border-color: var(--borderDark);
	padding: 15px 0;
}

.fx-block .ant-list-item .ant-list-item-meta-title {
	margin-bottom: 0;
}

.fx-list-header {
	display: flex;
	justify-content: space-between;
}

.fx-block .ant-list-item span,
.fx-list-header span {
	flex: 1;
	text-align: right;
}

.crypto-block .crypto-benfits {
	font-size: 20px;
}

.text-position {
	z-index: 1;
}

.crypto-block h2 {
	font-size: 52px;
	background: linear-gradient(180deg,
			#9cfacc,
			#6385ff 50%,
			#23b9ff 10%,
			#6385ff 10%);
	background-clip: text;
	-webkit-text-fill-color: transparent;
}

.gradient-btn,
.gradient-btn:hover,
.gradient-btn:focus {
	background: linear-gradient(130deg, #ff4d76, #a076cf, #6385ff);
	border-radius: 6px;
	border: none;
	width: 220px;
	height: 62px;
}

.name-text {
	font-weight: 700;
	background: linear-gradient(89.96deg,
			#a168ff -3.13%,
			#23b9ff 49.78%,
			#9cfacc 97.45%);
}

.wlecome-text {
	font-weight: 600;
	background: linear-gradient(93.77deg,
			#ff4d76 16.24%,
			#a076cf 48.07%,
			#6385ff 90.49%);
}

.goodmorning-text {
	font-weight: 600;
	font-size: 64px;
	color: var(--textWhite30) !important;
}

.wlecome-text,
.name-text {
	font-size: 74px;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	color: var(--textWhite30) !important;
}

/*----Landing Page end----*/
.radius16 {
	border-radius: 16px;
}

.virtival-icons {
	background: var(--bgGrey);
	padding: 6px 15px;
	border-radius: 8px;
	margin-right: 14px;
	text-align: center;
}

.virtual-box {
	background-color: transparent;
	border-radius: 30px;
	overflow: hidden;
	margin-bottom: 28px;
}

.virtual-box .card-info {
	border-top: 1px solid #515a64;
	font-size: 14px !important;
	display: flex;
	justify-content: space-between;
	padding: 20px 0;
	font-weight: 400;
}

.virtual-box .card-info .ant-typography {
	color: #fff;
}

.icon.md.cardleftarrow {
	background-position: -372px 5px;
	background-color: #465563;
	width: 30px;
	height: 30px;
	border-radius: 50px;
}

@media (min-width: 320px) {
	.tlv-header {
		padding: 8px 16px !important;
	}

	.header-dropdown {
		margin-right: 24px;
	}

	.megamenu-label {
		margin-top: 24px;
	}

	.header-right {
		display: none;
	}

	.mobile-header-right {
		display: block;
		float: right;
	}

	.sidebar-push {
		margin: 62px 0 0;
	}

	/* .side-drawer .ant-drawer-content-wrapper {
		width: 95% !important;
	} */

	/* .buysell-toggle {
		margin: 0 auto 0px !important;
	} */

	.crypto-percent {
		font-size: 36px;
		color: #ffffff;
		font-weight: 700;
	}

	.crypto-percent .percent {
		font-size: 16px;
		vertical-align: middle;
		margin-left: 4px;
	}

	.drawer-title {
		font-size: 28px !important;
		font-weight: 200 !important;
		color: var(--textWhite30) !important;
		margin: 16px 0 4px !important;
	}

	.buysell-toggle+.fs-36 {
		font-size: 28px !important;
	}

	.round-pills {
		margin-bottom: 16px;
	}

	.suissebgText {
		width: 100%;
		top: 80px;
	}

	.wlecome-text,
	.name-text {
		font-size: 24px;
	}

	.goodmorning-text {
		font-size: 36px;
	}

	.text-position {
		margin-top: 50px;
	}
}

@media (min-width: 576px) {
	.fiat-total {
		display: flex;
		justify-content: space-between;
		text-align: center;
	}

	.crypto-percent {
		font-size: 40px;
		line-height: normal;
	}

	.crypto-percent .percent {
		font-size: 20px;
		margin-left: 6px;
	}
}

@media (max-width: 767px) {
	.crypto-card .ant-card-body {
		height: 175px;
	}

	.mid-menu {
		margin: 0 -16px;
		padding: 0 16px;
		background-color: var(--bgWhite);
	}

	.header-logo {
		padding-left: 0 !important;
	}

	.mobile-none {
		display: none;
	}

	.mobile-megalinks,
	.notify {
		display: block;
	}

	.megamenu-label {
		font-size: 30px !important;
	}

	.megamenu-title {
		padding-right: 34px !important;
	}

	.mb-d-none {
		display: none;
	}

	.sidebar-push {
		margin: 62px 0 0 !important;
	}

	.copyright-block {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 12px;
	}

	.swap-subtitlte {
		width: 235px;
	}

	.enter-val-container {
		margin-right: 0px;
		justify-content: flex-start;
		margin-bottom: 16px;
	}

	.pay-list .ant-typography {
		display: block;
	}

	.coin.lg {
		transform: scale(0.8);
	}

	.card-icons {
		transform: scale(0.65);
		margin-right: -24px;
	}

	.portfolio-list .mobile-list .ant-list-item {
		display: flex;
	}

	.notify .ant-alert-icon {
		float: left;
	}

	.notify .ant-alert-content {
		margin-left: 40px;
	}

	.market-coinname {
		display: inline-block;
		max-width: 68px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.custom-theme-btn {
		display: block;
	}

	.display-flex {
		display: block;
		text-align: center;
	}

	.theme-switch {
		width: auto;
	}

	.profile-label {
		width: 150px;
	}

	.address-icons {
		list-style: none;
		padding-left: 0;
		margin-bottom: 12px;
		margin-top: 12px;
		display: flex;
	}

	.address-clear {
		clear: both;
		margin-top: 12px;
	}

	.customaudit-select .selectcustom-input {
		margin-left: 0 !important;
	}

	.customaudit-select .custom-btn {
		width: 100%;
	}

	.add-custom.ant-row {
		display: block !important;
	}

	.k-pager-sizes {
		display: none;
	}
}

@media (max-width: 1024px) {
	.custom-gridresponsive .ant-drawer-body {
		word-wrap: unset !important;
	}

	.custom-gridresponsive .k-grid-header col:nth-of-type(4) {
		width: 20% !important;
	}

	.custom-gridresponsive .k-grid-table col:nth-of-type(4) {
		width: 20% !important;
	}

	.custom-gridresponsive .k-pager-wrap .k-link {
		font: small-caption;
		font-size: 10px;
	}

	.custom-gridresponsive .k-pager-nav {
		min-width: calc(1.4285714286em + 3px);
		height: calc(1.4285714286em + 3px);
	}
}

@media (max-width: 991px) {
	.user-profile {
		width: 42px;
		height: 42px;
	}

	.basicinfo {
		font-size: 20px !important;
	}

	.user-list {
		padding-left: 0;
		display: block !important;
	}

	.mobile-list .ant-list-item {
		display: block;
		border-bottom: 1px solid var(--borderLight) !important;
	}

	.mobile-list .custom-btn,
	.crypto_btns .custom-btn {
		min-width: calc(50% - 8px);
	}

	.mobile-list .custom-btn:first-child {
		margin-left: 0 !important;
	}

	.mobile-list .crypto-btns {
		display: flex;
		padding-top: 16px;
	}

	.coin-typo {
		font-size: 12px !important;
	}

	.ant-modal-wrap.megamenu-backdrop {
		left: 12px !important;
	}

	.sidebar-push {
		margin: 100px 0 0;
	}

	.mobile-header-right {
		display: block;
		float: right;
	}

	.crypto_btns {
		display: flex;
		width: 100%;
		margin-top: 16px;
	}

	.hidden-mobile {
		display: none;
	}

	.profileinfo {
		padding: 15px 0;
	}

	.profile-value,
	.custom-toggle,
	.mobile-ml-8,
	.themeSwitchOff {
		margin-left: 0 !important;
	}

	.visible-mobile .ant-tabs-top>.ant-tabs-nav::before {
		border-bottom: 0 !important;
	}

	.visible-mobile .user-list .ant-tabs-nav {
		width: auto;
	}

	.mobile-mb-16 {
		margin-bottom: 16px;
	}
}

@media (min-width: 768px) {
	.crypto-card .ant-card-body {
		padding: 33px;
		height: 200px;
	}

	.tlv-header {
		display: flex;
		height: 100px;
		align-items: center;
		justify-content: space-between;
		padding: 30px 16px !important;
		border-radius: 0 0 30px 30px;
	}

	.markets-panel {
		margin-right: 16px;
	}

	.megamenu-label {
		margin-top: 0px;
		font-size: 33px !important;
	}

	.side-drawer .ant-drawer-content-wrapper {
		width: 420px !important;
	}

	.side-drawer .ant-drawer-body {
		padding: 0 20px 20px 20px;
	}

	.portfolio-count {
		display: inline-flex;
		height: 159px;
	}

	.megamenu-title .tlv-logo {
		padding-left: 34px !important;
	}

	.megamenu .ant-modal-header {
		border: none;
		padding: 24px 32px;
	}

	.megamenu .ant-modal-body {
		background-color: var(--bgGloom) !important;
	}

	.megamenu .ant-modal-close {
		top: 13px;
		right: 30px;
	}

	.megamenu-divider {
		height: 2px;
		background-color: var(--bgWhite);
		margin: 30px 0;
	}

	.megamenu-link {
		padding-left: 15px;
		padding-right: 15px;
		margin: 0 auto;
	}

	.mega-menu {
		padding-right: 30px;
		padding-left: 30px;
	}

	.wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: 230px;
	}

	.item-wrapper {
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: calc(100% - 10px);
	}

	.copyright {
		display: none !important;
	}

	.swap-subtitlte {
		width: 250px;
	}

	.enter-val-container {
		margin-right: 50px;
		margin-bottom: 8px;
	}

	.pay-list {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.verify-options {
		padding: 24px 70px;
	}

	.side-drawer .ant-drawer-header {
		padding: 20px !important;
	}

	.portfolio-list .ant-list-split .ant-list-item a {
		float: left;
	}

	.markets-panel .custom-table .ant-table-body {
		height: 875px !important;
		padding: 0 4px 0 0;
	}

	.custom-table .ant-table-tbody>tr>td:first-child {
		padding-left: 0;
	}

	.docfile {
		flex: 0 0 240px;
		width: auto;
	}

	.footer-links a {
		margin-right: 50px;
		text-align: left;
	}

	.theme-switch {
		width: 220px;
	}

	.custom-theme-btn,
	.profile-block {
		display: flex !important;
	}

	.themeSwitchOff,
	.themeSwitchOn {
		margin-left: 22px !important;
	}

	.profile-block {
		display: block;
	}

	.profile-label {
		width: 100px;
	}

	.display-flex {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.address-icons {
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
		display: flex;
	}

	.add-custom.ant-row {
		flex-flow: initial !important;
	}

	.wlecome-text,
	.name-text {
		font-size: 72px;
	}

	.goodmorning-text {
		font-size: 64px;
	}

	.text-position {
		margin-top: 0;
	}
}

@media (min-width: 992px) {
	.user-profile {
		width: 52px;
		height: 52px;
	}

	.basicinfo {
		font-size: 24px !important;
	}

	.right-panel {
		padding: 0 60px;
	}

	.tlv-header {
		padding: 24px 32px !important;
	}

	.summary-count {
		display: inline-block;
		vertical-align: top;
	}

	.markets-panel {
		margin-right: 40px;
	}

	.tlv-header .main-menu li {
		margin: 0 4px;
	}

	.sidebar-push {
		margin: 100px 0 0;
	}

	.header-right,
	.portfolio-list .ant-list-split .ant-list-item {
		display: block;
	}

	.header-right.mobile-header-right,
	.mobile-megalinks {
		display: none;
	}

	.crypto_btns {
		margin-left: 16px !important;
	}

	.crypto_btns .custom-btn.prime {
		margin-left: 36px !important;
	}

	.coin-typo {
		font-size: 16px !important;
	}

	.custom-table .ant-table-tbody>tr>td {
		padding: 20px 0 !important;
	}

	.portfolio-list .ant-list-split ul li .crypto_btns,
	.portfolio-list .ant-list-split ul li .custom-btn.prime {
		margin-left: 0 !important;
	}

	.portfolio-list .ant-list-split ul li .crypto_btns {
		margin-top: 10px;
	}

	.portfolio-list .ant-list-split ul li .custom-btn {
		min-width: calc(50% - 8px);
	}

	.visible-mobile {
		display: none;
	}

	.profileinfo {
		padding: 32px 0;
	}

	.custom-toggle {
		margin-left: 12px !important;
	}

	.mobile-ml-8 {
		margin-left: 4px;
	}

	.crypto-block {
		padding: 135px 30px;
	}
}

@media (min-width: 1200px) {
	.custom-font {
		font-size: 28px !important;
	}

	.main-container {
		max-width: 1232px;
		padding: 30px 16px 24px;
	}

	.mega-menu {
		max-width: 1140px;
		padding: 29px 0px 24px 60px;
	}

	.side-drawer.w-50p .ant-drawer-content-wrapper {
		width: 50% !important;
	}

	.side-drawer .ant-drawer-body {
		padding: 0 20px 20px 20px;
	}

	.portfolio-list .mobile-list {
		height: 816px;
		max-height: 816px;
		overflow: auto;
		padding-right: 10px;
	}

	.markets-panel .custom-table .ant-table-body {
		height: 1065px !important;
	}

	.crypto-address .walletadrs {
		font-size: 12px;
	}

	.trade-legends {
		display: flex;
		justify-content: space-between;
	}

	.portfolio-list .ant-list-split .ant-list-item a+div {
		min-width: 200px;
	}

	.portfolio-list .ant-list-split .ant-list-item {
		display: flex;
	}

	.portfolio-list .ant-list-split ul li .custom-btn {
		min-width: 90px;
	}

	.coin-details.right {
		min-height: 705px;
	}

	.suissebgText {
		width: 1100px;
		top: 10px;
	}
}

@media (min-width: 1400px) {
	.custom-font {
		font-size: 30px !important;
	}

	.main-container {
		max-width: 1320px;
	}

	.mega-menu {
		max-width: 1320px;
	}

	.side-drawer .ant-drawer-content-wrapper {
		width: 510px !important;
	}

	.side-drawer .ant-drawer-body {
		padding: 0 20px 20px 20px;
	}

	.side-drawer .side-drawer-header {
		padding: 15px 0px 15px 0px;
	}

	.crypto-list-tabs .crypto-list {
		max-height: calc(100vh - 350px);
		overflow-y: auto;
		padding-right: 10px;
	}

	.sellcrypto-container {
		max-height: calc(100vh - 230px);
	}

	.suisfiat-container,
	.suisfiat-height {
		max-height: calc(100vh - 195px);
	}

	.addCryptoList {
		max-height: calc(100vh - 218px);
		overflow-y: auto;
	}

	.round-pills .ant-radio-button-wrapper,
	.default-radio .ant-radio-button-wrapper {
		min-width: 120px;
	}

	.dep-withdraw {
		max-height: calc(100vh - 260px);
	}

	.crypto-card .ant-card-body {
		padding: 33px;
		height: 231px;
	}

	.crypto-percent {
		font-size: 60px;
		line-height: normal;
	}

	.coin {
		transform: scale(1);
	}

	.round-pills {
		margin-bottom: 30px;
		margin-top: 6px;
	}

	.drawer-title {
		font-size: 34px !important;
	}

	.crypto-search.ant-input-search .ant-input-group input {
		height: 58px !important;
	}

	.cancel-btn {
		height: 57px !important;
	}

	.custon-btngroup {
		height: 57px !important;
	}

	.to-receive {
		margin-top: 0px;
	}

	.pop-btn,
	.confirm-btn {
		height: 57px;
	}

	.crypto-list .ant-list-item,
	.wallet-list .ant-list-item {
		padding: 10px 8px 8px !important;
	}

	.crypto-search.ant-input-search .ant-input-group .ant-input-group-addon {
		border-radius: 0 30px 30px 0;
	}

	.pay-list {
		padding: 16px 0;
	}

	.crypto-container {
		max-height: calc(100vh - 325px);
	}

	.custom-crypto-address .fs-12 {
		font-size: 14px !important;
	}

	.crypto-address .walletadrs {
		font-size: 14px;
		margin-top: 6px;
	}

	.addbook-height {
		max-height: calc(100vh - 112px);
	}
}

.currencyContains {
	display: inline;
	color: var(--textWhite30);
}

.spinLoader {
	margin: 20px 0;
	margin-bottom: 20px;
	padding: 30px 50px;
	text-align: center;
	background: var(--bgWhiteSpin);
	border-radius: 4px;
}

.inputSpinner {
	display: block;
	text-align: left;
	height: 30px;
}

.copy-icon .ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
	color: #ffff;
	font-size: 20px;
	margin-left: 12px;
}

.crypto-address .ant-typography-expand,
.crypto-address .ant-typography-edit,
.crypto-address .ant-typography-copy {
	color: var(--textSecondary) !important;
	font-size: 20px;
	margin-left: 12px;
}

main.ant-layout-content {
	min-height: calc(100vh - 220px);
}

.notkyc {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	font-size: 36px;
	color: var(--textWhite);
	background-color: var(--bgDarkGrey);
	justify-content: center;
	margin-top: 150px;
	text-align: center;
}

.crypto-address.custom-crypto-address {
	padding: 8px 10px;
}

.crypto-address .ant-typography-copy svg {
	color: var(--textYellow);
}

.crypto-address .custom-display {
	position: absolute;
	top: 6px;
	right: 8px;
}

.crypto-address.custom-crypto-address .ant-typography-expand,
.crypto-address .ant-typography-edit,
.crypto-address .ant-typography-copy {
	font-size: 18px;
	margin-left: 0px;
}

.share-adrs {
	display: flex;
	justify-content: center;
	padding: 0;
	border-radius: 10px;
	background-color: var(--bgDarkGrey);
}

.share-adrs li {
	padding: 16px 20px 10px;
}

.share-adrs li:hover {
	background-color: var(--bgGrey);
}

.share-btn {
	background-color: transparent !important;
	color: var(--textWhite30) !important;
	border-color: var(--borderLight);
}

.notice-nodata {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 192px;
}

.transaction-tabs .ant-tabs-nav::before {
	border-bottom: none;
}

.transaction-tabs .ant-tabs-nav {
	margin-bottom: 15px;
}

.transaction-tabs .ant-drawer-body {
	padding: 10px 24px;
}

.transaction-tabs .ant-collapse-header {
	background: var(--bgDarkGrey) !important;
	color: var(--textWhite) !important;
}

.transaction-tabs .ant-collapse {
	background: transparent;
	border: 1px solid var(--borderGrey);
}

.transaction-tabs .ant-collapse-content {
	background: transparent;
	border: none;
}

.transaction-tabs .ant-collapse>.ant-collapse-item {
	border-bottom: 1px solid var(--borderGrey);
}

.transaction-tabs .ant-collapse-item .ant-collapse-arrow svg {
	transform: scale(1.3);
}

.transaction-tabs .ant-collapse-item-active .ant-collapse-arrow svg {
	transform: rotate(90deg) scale(1.3) !important;
}

.alltab-space .ant-table {
	margin: -16px -16px 0;
}

.custom-search .anticon-search {
	display: none;
}

.k-grid-header,
.k-grouping-header,
.k-grid-add-row,
.k-grid-footer {
	color: var(--textWhite) !important;
	background-color: transparent !important;
}

.k-grid-content,
.k-grid {
	background-color: transparent !important;
	color: var(--textWhite) !important;
}

.k-grid-content {
	margin-right: -12px;
}

.k-grid-header {
	padding-right: 0;
}

.k-grid tbody tr:hover,
.k-grid tbody tr.k-state-hover {
	background-color: var(--bgDarkGrey) !important;
}

.k-pager-wrap {
	color: var(--textWhite);
	background-color: transparent;
}

.change-space .ant-input-password-icon {
	padding: 10px 16px 10px 0 !important;
	cursor: pointer;
}

.change-space .ant-input-password-icon svg {
	color: var(--textWhite);
}

.hover-passlwngth {
	display: none;
}

.pass-onhover:hover+.hover-passlwngth {
	display: block;
	position: absolute;
	right: 0;
	background-color: #000;
	z-index: 999;
	padding: 10px;
}

.pass-onhover:hover+.hover-passlwngth span {
	display: block;
	color: var(--textWhite);
	padding: 3px 2px 2px 8px;
	font-size: 12px;
	position: relative;
}

.pass-onhover:hover+.hover-passlwngth span:first-child {
	padding: 0;
	margin-top: -5px;
	margin-left: -3px;
}

.hover-passlwngth:after {
	content: "";
	position: absolute;
	border: 12px solid transparent;
	border-top-color: #000 !important;
	border-bottom: 0;
	width: 8px;
	height: 8px;
	left: 2px;
	transform: rotate(175deg);
	top: -7px;
	z-index: 99;
}

.hover-passlwngth span:after {
	content: "";
	position: absolute;
	border: 6px solid transparent;
	border-top-color: #fff !important;
	border-left: 0;
	width: 4px;
	height: 4px;
	left: -7px;
	transform: rotate(135deg);
	top: 4px;
	z-index: 99;
}

.hover-passlwngth span:first-child:after {
	display: none;
}

.pwd-popup.pop-cancel {
	vertical-align: middle;
	margin: 10px auto !important;
	display: flex;
	height: 36px !important;
	text-align: center !important;
}

.pwd-popup.pop-cancel.ant-btn-block {
	width: auto !important;
}

.colr-comn {
	color: var(--textPureWhite) !important;
}

.ant-custumcheck {
	display: flex;
	align-items: center;
}

.ant-checkbox-inner {
	width: 32px !important;
	height: 32px !important;
	background-color: transparent !important;
	border: 0 !important;
}

.ant-custumcheck .ant-checkbox {
	display: inline-block;
	width: 34px;
	height: 34px;
	vertical-align: text-top;
	cursor: pointer;
	border: 1px solid var(--borderLight);
	background: transparent;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
	background: var(--lightThemeIcons);
	background-position: -33px -109px !important;
	border-color: transparent !important;
	display: inline-block;
	width: 34px;
	height: 34px;
	vertical-align: text-top;
	cursor: pointer;
	transform: rotate(0deg);
	margin-top: -15px;
	transition: none !important;
	left: 0;
}

.ant-custumcheck .ant-checkbox-checked,
label:hover .ant-checkbox-checked,
.ant-checkbox-checked::after {
	border: 1px solid transparent !important;
}

.customaudit-select .ant-select-single .ant-select-selector .ant-select-selection-search {
	left: 17px !important;
}

/* .transaction-tabs .k-grid-header-wrap{margin-right: -3px;} */
.customaudit-select .ant-form-item-label>label::after {
	display: none;
}

[data-theme="DRT"] .header-logo img,
[data-theme="LHT"] .header-logo img {
	display: none;
}

[data-theme="DRT"] .header-logo img.dark,
[data-theme="LHT"] .header-logo img.light {
	display: inline-block;
}

.notifications-list .ant-list-item-meta-avatar {
	border-radius: 50%;
	width: 38px;
	height: 38px;
	text-align: center;
	line-height: 38px;
}

.notifications-list .ant-list-item-meta-title {
	margin-bottom: 0 !important;
}

.notifications-list .ant-list-item-meta {
	align-items: center;
}

.notifications-list .ant-list-item {
	padding: 16px 0;
	border-bottom: 1px solid var(--borderGrey) !important;
}

.approvebg .ant-list-item-meta-avatar,
.depositbg .ant-list-item-meta-avatar,
.deposit.fiatbg .ant-list-item-meta-avatar,
.deposit.cryptobg .ant-list-item-meta-avatar {
	background-color: var(--bgDarkGreen);
}

.withdrawbg .ant-list-item-meta-avatar,
.depositsbg .ant-list-item-meta-avatar,
.withdraw.fiatbg .ant-list-item-meta-avatar,
.withdraw.cryptobg .ant-list-item-meta-avatar {
	background-color: var(--bgDarkRed);
}

.requestbg .ant-list-item-meta-avatar,
.documentsbg .ant-list-item-meta-avatar,
.cutomersbg .ant-list-item-meta-avatar {
	background-color: var(--bgBlue);
}

.notification-conunt .bell span {
	position: absolute;
	right: -5px;
	top: -7px;
	width: 18px;
	height: 18px;
	background: #f6921e;
	border-radius: 50%;
	line-height: 18px;
	text-align: center;
	font-size: 10px;
	font-weight: 600;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

.c-notallowed {
	cursor: not-allowed;
}

.tab-block span {
	display: block;
}

.card-list .ant-tabs-nav::before {
	border-bottom: 0 !important;
}

.card-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #ffffff !important;
	font-size: 16px !important;
	font-weight: 700;
}

.wallet-total {
	background: #515a64;
	border-radius: 30px;
	height: 132px;
}

.wallet-total .ant-list-item-meta-content {
	text-align: left;
}

.wallet-total .ant-list-item-meta-title {
	line-height: 27.4px;
}

.card-progress .ant-progress-success-bg {
	background-color: #f81506;
	width: 35% !important;
}

.card-progress .ant-progress-bg {
	background-color: #b0f07d;
}

.card-module.crypto-card {
	background: linear-gradient(152.27deg,
			#b30f1e 18.18%,
			#0038ff 119.22%) !important;
	border: 0.5px solid #1b4156;
	box-sizing: border-box;
	box-shadow: 0px 15px 20px 10px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(20px);
	border-radius: 12px;
}

.virtual-card.crypto-card {
	background: linear-gradient(152.27deg,
			#0a2979 18.18%,
			#0b1a41 121.72%) !important;
	border: 0.5px solid #1b4156;
	box-sizing: border-box;
	box-shadow: 0px 15px 20px 10px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(20px);
	border-radius: 12px;
}

.card-module.crypto-card .ant-card-body,
.crypto-card.crypto-card .ant-card-body {
	height: 230px !important;
	padding: 20px;
	text-align: left;
}

.cardholder-name {
	font-size: 24px;
	line-height: 25px;
}

.valid-time {
	margin-right: 40px;
}

.dark .ant-tabs-tab-btn:focus{
	color: var(--textDark) !important;
}

.ant-tabs-tab-btn:focus,
.card-list .ant-tabs-tab-remove:focus,
/* .ant-tabs-tab-btn:active, */
.ant-tabs-tab-remove:active,
.ant-tabs-tab:hover {
	color: #ffffff !important;
}

.ant-tabs-ink-bar {
	background-color: transparent;
}

.disabled .ant-card-body,
.disabled .bggreen,
.disabled .card-progress .ant-progress-success-bg,
.disabled .ant-progress-success-bg {
	background-color: #7a7b7c;
	color: #858585;
}

.disabled .text-white,
.disabled .text-white-50,
.disabled .text-purewhite {
	color: #ccc !important;
}

/* Footer */
.main-container {
	padding: 24px 16px;
	margin: auto;
}

.footer-links a {
	font-size: 14px;
	color: var(--textWhite30);
	font-weight: var(--fw500);
	display: inline-block;
	margin-right: 30px;
	text-align: center;
}

.footer-links a:hover {
	color: var(--textAqua) !important;
}

/*Header*/
.text-plain {
	cursor: unset;
}
.user-list .ant-tabs-tab:hover{color: #000 !important;}
.user-list .ant-tabs-tab-btn:focus, .card-list .ant-tabs-tab-remove:focus, .ant-tabs-tab-remove:active, .ant-tabs-tab:hover{color: #000 !important;}